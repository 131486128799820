import {
	CHECK_RESTAURANT_OPERATION_SERVICE,
	GET_RESTAURANTS_BASED_ON_CATEGORY,
	GET_RESTAURANTS_CATEGORIES,
	GET_RESTAURANTS_ITEM_CATEGORIES,
	GET_RESTAURANTS_DEAL_ITEMS,
	GET_RESTAURANTS_COMBOS,
	GET_RESTAURANTS_FEATURED_ITEMS,
	GET_LINKED_ITEMS,
	GET_DELIVERY_RESTAURANTS,
	GET_SELFPICKUP_RESTAURANTS,
	GET_FAVORITE_RESTAURANTS,
	GET_FAVORITE_ITEMS,
} from "./actionTypes";
import {
	CHECK_RESTAURANT_OPERATION_SERVICE_URL,
	GET_RESTAURANTS_CATEGORIES_URL,
	GET_FILTERED_RESTAURANTS_URL,
	GET_DELIVERY_RESTAURANTS_URL,
	GET_SELFPICKUP_RESTAURANTS_URL,
	GET_FAVORITE_RESTAURANTS_URL,
	GET_FAVORITE_ITEM_LIST_URL,
	GET_RESTAURANTS_ITEM_CATEGORIES_URL,
	GET_RESTAURANTS_DEAL_ITEMS_URL,
	GET_RESTAURANTS_COMBOS_URL,
	GET_RESTAURANTS_DEAL_ITEMS_WITH_FAVORITE_URL,
	GET_RESTAURANTS_FEATURED_ITEMS_URL,
	GET_RESTAURANTS_FEATURED_ITEMS_WITH_FAVORITE_URL,
	GET_LINKED_ITEMS_URL,
	GET_LINKED_ITEMS_WITH_FAVORITE_URL,
} from "../../configs";
import Axios from "axios";

export const checkRestaurantOperationService = (restaurant_id, latitude, longitude) => (dispatch) => {
	Axios.post(CHECK_RESTAURANT_OPERATION_SERVICE_URL, {
		restaurant_id: restaurant_id,
		latitude: latitude,
		longitude: longitude,
	})
		.then((response) => {
			const coupon = response.data;
			return dispatch({ type: CHECK_RESTAURANT_OPERATION_SERVICE, payload: coupon });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsBasedOnCategory = (lat, lng, category_ids) => (dispatch) => {
	Axios.post(GET_FILTERED_RESTAURANTS_URL, {
		latitude: lat,
		longitude: lng,
		category_ids: category_ids,
	})
		.then((response) => {
			const filtered_restaurants = response.data;
			return dispatch({
				type: GET_RESTAURANTS_BASED_ON_CATEGORY,
				payload: filtered_restaurants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsCategories = (slug) => (dispatch) => {
	Axios.post(GET_RESTAURANTS_CATEGORIES_URL)
		.then((response) => {
			const restaurants_categories = response.data;
			return dispatch({
				type: GET_RESTAURANTS_CATEGORIES,
				payload: restaurants_categories,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getDeliveryRestaurants = (lat, lng) => (dispatch) => {
	return Axios.post(GET_DELIVERY_RESTAURANTS_URL, {
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const restaurants = response.data;
			return dispatch({
				type: GET_DELIVERY_RESTAURANTS,
				payload: restaurants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getSelfpickupRestaurants = (lat, lng) => (dispatch) => {
	return Axios.post(GET_SELFPICKUP_RESTAURANTS_URL, {
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const restaurants = response.data;
			return dispatch({
				type: GET_SELFPICKUP_RESTAURANTS,
				payload: restaurants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getFavoriteRestaurants = (lat, lng) => (dispatch,getState) => {
	return Axios.post(GET_FAVORITE_RESTAURANTS_URL, {
		token: getState().user.user.data.auth_token,
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const restaurants = response.data;
			return dispatch({
				type: GET_FAVORITE_RESTAURANTS,
				payload: restaurants,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getFavoriteItems = (lat, lng) => (dispatch,getState) => {
	return Axios.post(GET_FAVORITE_ITEM_LIST_URL, {
		token: getState().user.user.data.auth_token,
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
            const items = response.data;
            console.log('items', items);
			return dispatch({
				type: GET_FAVORITE_ITEMS,
				payload: items,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsItemCategories = (lat, lng) => (dispatch) => {
	return Axios.post(GET_RESTAURANTS_ITEM_CATEGORIES_URL, {
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const restaurants_item_categories = response.data;
			return dispatch({
				type: GET_RESTAURANTS_ITEM_CATEGORIES,
				payload: restaurants_item_categories,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsDealItems = (lat, lng, is_randomize = false) => (dispatch,getState) => {
    var restaurant_deal_items_url =  getState().user.user.data !== undefined 
                ? GET_RESTAURANTS_DEAL_ITEMS_WITH_FAVORITE_URL 
                : GET_RESTAURANTS_DEAL_ITEMS_URL;
	return Axios.post(restaurant_deal_items_url, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		latitude: lat,
		longitude: lng,
        is_randomize: is_randomize
	})
		.then((response) => {
			const restaurants_deal_items = response.data;
			return dispatch({
				type: GET_RESTAURANTS_DEAL_ITEMS,
				payload: restaurants_deal_items,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsFeaturedItems = (lat, lng, is_randomize = false) => (dispatch,getState) => {
    var restaurant_featured_items_url =  getState().user.user.data !== undefined 
                ? GET_RESTAURANTS_FEATURED_ITEMS_WITH_FAVORITE_URL 
                : GET_RESTAURANTS_FEATURED_ITEMS_URL;
	return Axios.post(restaurant_featured_items_url, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		latitude: lat,
		longitude: lng,
        is_randomize: is_randomize
	})
		.then((response) => {
			const restaurants_featured_items = response.data;
			return dispatch({
				type: GET_RESTAURANTS_FEATURED_ITEMS,
				payload: restaurants_featured_items,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getLinkedItems = (lat, lng, is_randomize = false, slug) => (dispatch,getState) => {
    var linked_items_url =  getState().user.user.data !== undefined 
                ? GET_LINKED_ITEMS_WITH_FAVORITE_URL 
                : GET_LINKED_ITEMS_URL;
	return Axios.post(linked_items_url, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		latitude: lat,
		longitude: lng,
        is_randomize: is_randomize,
		slug: slug
	})
		.then((response) => {
			const linked_items = response.data;
			return dispatch({
				type: GET_LINKED_ITEMS,
				payload: linked_items,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getRestaurantsCombos = (lat, lng, is_randomize = false) => (dispatch,getState) => {
	return Axios.post(GET_RESTAURANTS_COMBOS_URL, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		latitude: lat,
		longitude: lng,
        is_randomize: is_randomize
	})
		.then((response) => {
			const restaurants_combos = response.data;
			return dispatch({
				type: GET_RESTAURANTS_COMBOS,
				payload: restaurants_combos,
			});
		})
		.catch(function(error) {
			console.log(error);
		});
};
