import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import CarouselSlider from "./CarouselSlider";
import { Redirect } from "react-router";
// import RestaurantList from "./RestaurantList";
import CategoryList from "./CategoryList";
import DealOfTheDayList from "./DealOfTheDayList";
import FrequentlyBoughtList from "./FrequentlyBoughtList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { getDynamicFeaturedSections } from "../../../services/dynamicFeaturedSection/actions";

import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
import { resetInfo, resetItems, resetBackup, getRestaurantItems, getRestaurantInfo, getRestaurantInfoForLoggedInUser } from "../../../services/items/actions";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import NoAreaAlert from "./NoAreaAlert";
import Combos from "./Combos";
import DynamicFeaturedSectionStyle1 from "./DynamicFeaturedSectionStyle1";
import DynamicFeaturedSectionStyle2 from "./DynamicFeaturedSectionStyle2";
import LazyLoad from "react-lazyload";
import DelayLink from "../../helpers/delayLink";
import ContentLoader from "react-content-loader";
// import moment from "moment";
import Lottie from "lottie-react";

class HomeV1 extends Component {
	state = {
		update: true,
        no_area: false,
        restaurant_items_update: false,
        animationData: null,
        fruits: [],
        currentFruit: 0,
        searchTerm:'',
        showFruit: true, 
        catLoad:false,
        
	};
    
	static contextTypes = {
		router: () => null,
	};
   
	async componentDidMount() {
		this.props.resetItems();
		this.props.resetInfo();
		this.props.resetBackup();

		const { user } = this.props;

		//if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
		//else make API calls
		if (localStorage.getItem("userSetAddress") !== null) {
			// this.context.router.history.push("/search-location");
			// console.log("Redirect to search location");
			// return <Redirect to="/search-location" />;
			if (localStorage.getItem("showPromoSlider") === "true") {
				const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

				this.props.getDynamicFeaturedSections(userSetAddress.lat, userSetAddress.lng);
				this.props.getPromoSlides(userSetAddress.lat, userSetAddress.lng);
			}

			const { user } = this.props;

			if (user.success) {
				this.props.getUserNotifications(user.data.id, user.data.auth_token);
			}
		} else {
			//call to promoSlider API to fetch the slides
		}

		if (user.success) {
			if (localStorage.getItem("enablePushNotification") === "true") {
				if (firebase.messaging.isSupported()) {
					// const today = moment().toDate();

					// console.log("TODAY", today);
					// const lastSavedNotificationToken = moment(localStorage.getItem("lastSavedNotificationToken"));
					// const days = moment(today).diff(lastSavedNotificationToken, "days");

					// console.log("DAYS", days);

					// const callForNotificationToken = isNaN(days) || days >= 5;

					// console.log(callForNotificationToken);
					// if (callForNotificationToken) {
					let handler = this.props.saveNotificationToken;
					messaging
						.requestPermission()
						.then(async function() {
							const push_token = await messaging.getToken();
							handler(push_token, user.data.id, user.data.auth_token);
							// localStorage.setItem("lastSavedNotificationToken", today);
						})
						.catch(function(err) {
							console.log("Unable to get permission to notify.", err);
						});
					// }
				}
			}
		}
        this.setState({catLoad:true})
		
        const response = await fetch('https://lottie.host/a15bc3e0-2342-4259-b869-6633cbb9ba99/KY2ihKl3Ou.json');
       
        const data = await response.json();
        this.setState({ animationData: data });

        const fruitString = localStorage.getItem("mockSearchPlaceholder"); // Access fruit string from props
        const trimmedString = fruitString.replace(/Search for |<"|>/g, "");
        const fruits = trimmedString.split(" ").map(fruit => fruit.slice(0, -1));
        const indexOfFirstSpace = localStorage.getItem("mockSearchPlaceholder").indexOf(' ');
        const indexOfSecondSpace = localStorage.getItem("mockSearchPlaceholder").indexOf(' ', indexOfFirstSpace + 1); // Search after first space
        const searchTerm = localStorage.getItem("mockSearchPlaceholder").slice(0, indexOfSecondSpace !== -1 ? indexOfSecondSpace : localStorage.getItem("mockSearchPlaceholder").length);
        this.setState({searchTerm:searchTerm})
        this.checkItemsInCat(11,6)
  this.setState({ fruits });

        this.intervalId = setInterval(() => {
            this.setState((prevState) => ({
              currentFruit: (prevState.currentFruit + 1) % this.state.fruits.length,
              showFruit: false, // Reset animation trigger
            }));
      
            setTimeout(() => {
              this.setState({ showFruit: true }); // Show next fruit with animation
            }, 0); // Schedule after current render cycle
          }, 3000);

          

	}

	componentWillReceiveProps(nextProps) {
		if (this.props.languages !== nextProps.languages) {
			if (localStorage.getItem("userPreferedLanguage")) {
				this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
			} else {
				if (nextProps.languages.length) {
					// console.log("Fetching Translation Data...");
					const id = nextProps.languages.filter((lang) => lang.is_default === 1)[0].id;
					this.props.getSingleLanguageData(id);
				}
			}
		}
	}
   
	 componentWillUnmount() {
    clearInterval(this.intervalId); // Clean up interval when component unmounts
  }
   
     
    noAreaAlert = (no_area) => {
        this.setState({ no_area: no_area });
    }

	forceStateUpdate = () => {
        if (this.state.update) {
            this.setState({ update: false });
        } else {
            this.setState({ update: true });
        }

        return this.state.update;
	};

	updateRestaurantItemsState = (action) => {
        this.setState({ restaurant_items_update: action });
	};

    checkItemsInCat = (res,id) =>{
        const { user } = this.props;
        let info = user.success
        ? this.props.getRestaurantInfoForLoggedInUser(res)
        : this.props.getRestaurantInfo(res);
    const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
    if (info) {
        info.then(async (response) => {
            if (response) {
                if (response.payload.id) {
                    //get items
                    if(userSetAddress){
                        await this.props.getRestaurantItems(res, id,userSetAddress.lat, userSetAddress.lng);
                    } else {
                        // await this.props.getRestaurantItems(res, id,userSetAddress.lat, userSetAddress.lng);
                        await this.props.getRestaurantItems(res, id, '17.4218701', '78.3388073');
                    }
                } else {
                    //404, redirect to homepage
                    this.context.router.history.push("/");
                }
                if (response.payload.is_active === 1 || response.payload.is_active === 0) {
                    this.setState({ loading: false });
                    this.setState({ is_active: response.payload.is_active });
                } 
            }
        });
    }
    }
    
	render() {
		if (window.innerWidth > 768) {
			return <Redirect to="/" />;
		}

		if (localStorage.getItem("userSetAddress") === null) {
			// this.context.router.history.push("/search-location");
			// console.log("Redirect to search location");
			return <Redirect to="/search-location" />;
		}

		const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
		if (Object.keys(userSetAddress).length === 0 && userSetAddress.constructor === Object) {
			return <Redirect to="/search-location" />;
		}
        // this.checkItemsInCat(11,6)
        const { fruits } = this.props;
        const carouselClass = this.state.showFruit ? 'fruit-carousel show' : 'fruit-carousel';
		const { history, user, promo_slides, dynamic_featured_sections } = this.props;
        
        const afterCategorySliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 1) : [];
        const afterDealofthedaySliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 2) : [];
        const afterFrequentlyBoughtSliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 3) : [];
      

		return (
			<React.Fragment>
				<Meta
					seotitle={localStorage.getItem("seoMetaTitle")}
					seodescription={localStorage.getItem("seoMetaDescription")}
					ogtype="website"
					ogtitle={localStorage.getItem("seoOgTitle")}
					ogdescription={localStorage.getItem("seoOgDescription")}
					ogurl={window.location.href}
					twittertitle={localStorage.getItem("seoTwitterTitle")}
					twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>

				<div className="height-100-percent bg-white mb-50">
                <div style={{backgroundImage:`url('/assets/img/homeV1headerBG.avif')`,paddingTop:10}}>

					<Nav 
						logo={true}
						active_nearme={true}
						disable_back_button={true}
						history={history}
						loggedin={user.success}
					/>

                    <div style={{position:'absolute',top:18,left:42,zIndex:2}}>
                        <button
                            type="submit"
                            className="btn nav-location nav-home-location truncate-text mb-5"
                            style={{ position: "relative", maxWidth: "100%",background:'none' }}
                            onClick={() => {
                                this.props.loggedin
                                    ? this.context.router.history.push("/my-addresses")
                                    : this.context.router.history.push("/search-location");
                            }}
                        >
                            {localStorage.getItem("userSetAddress") && (
                                <React.Fragment>
                                    <span className="text-normal deliver-to" style={{fontFamily: 'Basis_Grotesque_Pro_Bold',
                                    fontWeight: 700,fontSize: 16,lineHeight: '19px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.92)',
                                    textTransform:'none'}}>
                                        {localStorage.getItem('deliveryToText')} {JSON.parse(localStorage.getItem("userSetAddress")).tag} <img style={{width:15}} src="/assets/img/downArrow.png"/>
                                    </span>
                                    <div className="deliver-to-with-address" style={{left:-4}}>
                                            <span style={{fontFamily: 'Basis_Grotesque_Regular',fontWeight: 200,
                                            fontSize: 13,lineHeight: '16px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.6)'}}>

                                            {JSON.parse(localStorage.getItem("userSetAddress")).address
                                                    .length > 30
                                                    ?
                                                    <>
                                                    {
                                                        JSON.parse(localStorage.getItem("userSetAddress")).house
                                                        .length > 15 ?
                                                        <>
                                                        {JSON.parse(localStorage.getItem("userSetAddress")).house}
                                                        </>
                                                        :
                                                        <>
                                                        { JSON.parse(
                                                            localStorage.getItem("userSetAddress")
                                                        ).house.substring(0, 30)},{" "}
                                                     {JSON.parse(localStorage.getItem("userSetAddress")).address
                                                            .length > 25
                                                            ? `${JSON.parse(
                                                                    localStorage.getItem("userSetAddress")
                                                                ).address.substring(0, 25)}...`
                                                            : JSON.parse(localStorage.getItem("userSetAddress"))
                                                                    .address}
                                                        </>
                                                    }
                                                   
                                                    </>
                                                    : <>
                                                   { JSON.parse(localStorage.getItem("userSetAddress"))
                                                            .address}, {JSON.parse(localStorage.getItem("userSetAddress")).address
                                                            .length > 15
                                                            ? `${JSON.parse(
                                                                    localStorage.getItem("userSetAddress")
                                                                ).address.substring(0, 15)}...`
                                                            : JSON.parse(localStorage.getItem("userSetAddress"))
                                                                    .address}
                                                    </>
                                                            }
                                                {/* {
                                                    JSON.parse(localStorage.getItem("userSetAddress")).house !== '' &&
                                                    <span>{JSON.parse(localStorage.getItem("userSetAddress")).house}, </span>  
                                                } */}
                                               
                                            </span>
                                            
                                        {/* <img src="./assets/img/home/pinned-location.png" alt="location" />
                                        <span>
                                            {JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
                                                <strong className="text-uppercase mr-1">
                                                    {JSON.parse(localStorage.getItem("userSetAddress")).tag}
                                                </strong>
                                            ) : null}
                                        </span>
                                        {JSON.parse(localStorage.getItem("userSetAddress")).house !==
                                        null ? (
                                            <span>
                                                {JSON.parse(localStorage.getItem("userSetAddress")).house
                                                    .length > 12
                                                    ? `${JSON.parse(
                                                            localStorage.getItem("userSetAddress")
                                                        ).house.substring(0, 12)}...`
                                                    : JSON.parse(localStorage.getItem("userSetAddress"))
                                                            .house}
                                            </span>
                                        ) : (
                                            <span>
                                                {JSON.parse(localStorage.getItem("userSetAddress")).address
                                                    .length > 30
                                                    ? `${JSON.parse(
                                                            localStorage.getItem("userSetAddress")
                                                        ).address.substring(0, 30)}...`
                                                    : JSON.parse(localStorage.getItem("userSetAddress"))
                                                            .address}
                                            </span>
                                        )} */}
                                    </div>
                                </React.Fragment>
                            )}
                            <Ink duration="500" />
                        </button>
                    </div>
                    { this.state.no_area ? (
                        <NoAreaAlert />
                    ) : (
                        <React.Fragment>
                           
                    {
                            localStorage.getItem("mockSearchOnHomepage") === "true" && (
                                <Link to="/explore" className="mt-3">
                                    <div
                                        className={`mock-search-block px-15 ${
                                            localStorage.getItem("showPromoSlider") === "false"
                                                ? "pt-0"
                                                : "" + promo_slides.mainSlides === "null"
                                                ? "pt-0"
                                                : ""
                                        }`}
                                    >
                                        <div className="px-15 d-flex justify-content-between" style={{borderRadius:12,overflow:'hidden'}}>
                                            <div>
                                                <div className="d-flex" style={{fontFamily: 'Basis_Grotesque_Regular',fontWeight: 400,
                                            fontSize: 16,lineHeight: '19px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.45)'}}>
                                              <div className="mr-5">{this.state.searchTerm}</div>  
                                                <div className={carouselClass}>
                                                <div className="fruit-text" key={this.state.currentFruit}>"{this.state.fruits[this.state.currentFruit]}"</div>
                                            </div>

                                            
                                               
                                            </div>
                                            </div>
                                            <div>
                                                <img style={{width:20}} src="./assets/img/searchSvg.svg" alt="search-location" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                <div style={{backgroundImage:`url('/assets/img/homeV1BG2.png')`,position:'relative',height:118,backgroundPosition:'center',backgroundSize:'contain',marginTop:10}}>
                    <img style={{position:'absolute',right:20,width:70,height:35,top:10}} src="/assets/img/cloudHomeV1BG.webp" />
                    <img style={{position:'absolute',left:20,top:40,width:50,height:25}} src="/assets/img/cloudHomeV1BG.webp" />
                    <div className="d-flex justify-content-center">
                    <Lottie style={{height:250,width:500,position:'absolute',top:0,display:'flex',justifyContent:'center'}} animationData={this.state.animationData} loop={true} />
                    </div>

                </div>

                <div style={{background:'white'}}>
                <div style={{background:'white',paddingLeft:15,paddingBottom:0,paddingTop:120}} 
                className="shop-by-category-content-section" >
                                    {this.props.restaurant_items.length === 0 ? (
                                        <ContentLoader
                                            height={378}
                                            width={400}
                                            speed={1.2}
                                            primaryColor="#f3f3f3"
                                            secondaryColor="#ecebeb"
                                        >
                                            <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                            <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                                            <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                                            <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                                            <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                                            <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                                            <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                                            <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                                            <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                                        </ContentLoader>
                                    ) : (
                                        <div className="d-flex ">
                                    {this.props.restaurants_item_categories.length !== 0 && this.props.restaurant_items.items !== undefined  && this.props.restaurant_items.items.map((category, index) => (
                                     
                                     <React.Fragment key={index}>
                                    
                                     <LazyLoad>
                                         <div className="single-category-section">
                                             <DelayLink
                                                 to={"../categories/" + this.props.restaurants_item_categories[0].restaurant_slug + "/" + category.id}
                                                 delay={200}
                                                 className="block text-center mb-1"
                                             >
                                                 <div className="category-content">
                                                     <img
                                                         src={`${category.image}`}
                                                         alt={category.name}
                                                         className="slider-wrapper__img slider-cust-img"
                                                     />
                                                     <div className="text-dark p-5" style={{fontSize:10}}>
                                                         {category.name}
                                                     </div>
                                                 </div>
                                                 <Ink duration="500" hasTouch={false} />
                                             </DelayLink>
                                         </div>

                                         {/* <div className="single-category-section mr-15" style={{width:100}}>
                                             <DelayLink
                                                 to={"../categories/" + category.restaurant_slug + "/" + category.id}
                                                 delay={200}
                                                 className="block text-center mb-1"
                                             >
                                                 <div className="category-content " style={{height:108,position:'relative',
                                                     background: index%2 === 0 ? '#EED586' : '#E8CBFA',width:100,marginTop:25,borderRadius:18,
                                                     border: index%2 === 0 ? '3px solid #EDC87B' : '3px solid #D7C5EE'}}>
                                                     <img
                                                         style={{borderRadius:50,position:'absolute',top:-25,left:8}}
                                                         src={`https://tp.ourdev.in/${category.image}`}
                                                         alt={category.name}
                                                         className="slider-wrapper__img slider-cust-img"
                                                     />
                                                     <div  className="p-5" style={{fontSize:12,color:index%2 === 0 ? '#704617':'#8848B1',
                                                                             top:50,position:'absolute',
                                                                             fontSize:14,width:'100%',fontWeight:'bold'}}>
                                                         {category.name}
                                                     </div>
                                                 </div>
                                                 <Ink duration="500" hasTouch={false} />
                                             </DelayLink>
                                         </div> */}
                                     </LazyLoad>
                                 
                                         
                                 
                                 </React.Fragment>
                                    ))}
                                       {/* { this.props.restaurants_item_categories.map((category, index) => (
                                           <>
                                          {
                                            this.props.restaurant_items.length !=0 && this.props.restaurant_items.items.map((resItem)=>
                                            
                                            <>
                                            {
                                                (resItem.id == category.id && resItem.items.length != 0) &&
                                                       <>
                                                       </>
                                            }
                                            </>
                                            
                                            )
                                          }
                                                
                                           </>
                                        ))} */}
                                        </div>
                                    )}
                                </div>
                            
                <Link to={"../categories/1/1"} style={{background:'white',display:'flex',justifyContent:'center',paddingBottom:20}}>
                    <img style={{width:390,height:30}} src="/assets/img/exploreNow.avif" />
                </Link>
                </div>
                <div style={{background:'white'}}>

                <React.Fragment>
                            
                            {localStorage.getItem("customHomeMessage") !== "<p><br></p>" &&
                                localStorage.getItem("customHomeMessage") !== "null" &&
                                (localStorage.getItem("customHomeMessage") !== "" && (
                                    <div
                                        style={{
                                            position: "relative",
                                            background: "white",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: localStorage.getItem("customHomeMessage"),
                                        }}
                                    />
                                ))}
                            {/* <RestaurantList user={user} slides={promo_slides.otherSlides} /> */}
                            
                            {/* Passing slides as props to PromoSlider */}
                            
                            

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.main_section !== undefined && dynamic_featured_sections.main_section.map(section => (
                                        <React.Fragment key={section.id}>
                                           
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='main' style_type="1" />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='main' style_type="2" />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {promo_slides && promo_slides.mainSlides && promo_slides.mainSlides.length > 0 && (
                                        <>
                                        <CarouselSlider
                                            slides={promo_slides.mainSlides}
                                            size={promo_slides.mainSlides[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                        />
                                        </>
                                        
                                    )}
                                </React.Fragment>
                            )}
                        
                            <CategoryList noAreaAlert={this.noAreaAlert} user={user} slides={promo_slides.otherSlides} display={false} />

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.category_section !== undefined && dynamic_featured_sections.category_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='category' style_type="1" />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='category' style_type="2" />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                                {/* offerBanner.avif */}
                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                   
                                    {afterCategorySliders && afterCategorySliders.length > 0 && (
                                        <>  
                                      
                                        <CarouselSlider
                                            slides={afterCategorySliders}
                                            size={afterCategorySliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                            slider_Type="afterCategory"
                                        />
                                        </>
                                    )}
                                </React.Fragment>
                            )}

                            <DealOfTheDayList 
                            updateRestaurantItemsState={this.updateRestaurantItemsState} 
                            forceRestaurantItemsUpdate={this.state.restaurant_items_update} 
                            forceStateUpdate={this.forceStateUpdate} 
                            user={user} 
                            slides={promo_slides.otherSlides} />

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.deal_of_the_day_section !== undefined && dynamic_featured_sections.deal_of_the_day_section.map(section => (
                                        
                                        <React.Fragment key={section.id}>
                                           
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='deal_of_the_day' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='deal_of_the_day' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {afterDealofthedaySliders && afterDealofthedaySliders.length > 0 && (
                                        <>
                                          <div>
                                            <img style={{width:'100%'}} src="/assets/img/receipeBanner.avif" />
                                          </div>
                                        <CarouselSlider
                                            slides={afterDealofthedaySliders}
                                            size={afterDealofthedaySliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                            type="ccccc"
                                        />
                                        </>
                                    )}
                                </React.Fragment>
                            )}

                            <Combos updateRestaurantItemsState={this.updateRestaurantItemsState} forceRestaurantItemsUpdate={this.state.restaurant_items_update} forceStateUpdate={this.forceStateUpdate} user={user} slides={promo_slides.otherSlides} />

                            <FrequentlyBoughtList 
                            updateRestaurantItemsState={this.updateRestaurantItemsState} 
                            forceRestaurantItemsUpdate={this.state.restaurant_items_update} 
                            forceStateUpdate={this.forceStateUpdate} 
                            user={user} 
                            slides={promo_slides.otherSlides} />
                            
                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.frequenty_bought_section !== undefined && dynamic_featured_sections.frequenty_bought_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='frequently_bought' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle2 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='frequently_bought' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {afterFrequentlyBoughtSliders && afterFrequentlyBoughtSliders.length > 0 && (
                                        <CarouselSlider
                                            slides={afterFrequentlyBoughtSliders}
                                            size={afterFrequentlyBoughtSliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            <img width="100%" style={{
                                padding: "25px"
                            }} alt="test" src="./assets/img/food-feel-good.png" />
                            <Footer active_nearme={true} />
                        </React.Fragment>
                </div>
                            </React.Fragment>
                    )
                }
                
                </div>
                

                    {/* { this.state.no_area ? (
                        <NoAreaAlert />
                    ) : (
                       <></>
                    )} */}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
    promo_slides: state.promo_slides.promo_slides,
	dynamic_featured_sections: state.dynamic_featured_sections.dynamic_featured_sections,
	user: state.user.user,
	locations: state.locations.locations,
	languages: state.languages.languages,
    restaurants_item_categories: state.restaurant.restaurants_item_categories,
    restaurant_items: state.items.restaurant_items,
});

export default connect(
	mapStateToProps,
	{
		getPromoSlides,
		getDynamicFeaturedSections,
		saveNotificationToken,
		getSingleLanguageData,
		getUserNotifications,
		resetInfo,
		resetItems,
		resetBackup,
        getRestaurantItems,
        getRestaurantInfo,
        getRestaurantInfoForLoggedInUser
	}
)(HomeV1);
