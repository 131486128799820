import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Lottie from "lottie-react";
import Modal from "react-responsive-modal";
import Loading from "../../../helpers/loading";
import Ink from "react-ink";
import ContentLoader from "react-content-loader";
import AddressList from "../../Account/Addresses/AddressList";
import { deleteAddress, getAddresses, saveAddress, setDefaultAddress } from "../../../../services/addresses/actions";
import { calculateDistance } from "../../../helpers/calculateDistance";
import calculateDistanceGoogle from "../../../helpers/calculateDistanceGoogle.js";
import { getRestaurantInfoById, getRestaurantInfoAndOperationalStatus } from "../../../../services/items/actions";
import { checkUserRunningOrder, updateUserInfo, checkBan } from "../../../../services/user/actions";
import { checkCartItemsAvailability } from "../../../../services/confirmCart/actions.js";
import { addProduct } from "../../../../services/cart/actions.js";
import { updateCart } from "../../../../services/total/actions.js";
import { Dialog } from "@material-ui/core";
class FarmVisitForm extends Component {
	state = {
		submitted: false,
		formData: {},
		numberAdults:0,
		numberKids:0,
		animationData: null,
		Name:"",
		mobileNumber:"",
		location:"",
		open:false,
		addressesLoading: false,
		openError:false,
		activeDate:"",
		datesData: [
			{date:'8 June',
			 day:'Sat',
			 dateTosend:'2024-06-08',
			 filled:true
			},
			{date:'9 June',
			 day:'Sun',
			 dateTosend:'2024-06-09',
			 filled:true
			},
			{date:'15 June',
			 day:'Sat',
			 dateTosend:'2024-06-15',
			 filled:false
			},
			{date:'16 June',
			 day:'Sun',
			 dateTosend:'2024-06-16',
			 filled:false
			}
		]
	  };
	
	  static contextTypes = {
		router: () => null,
	  };
	
	  async componentDidMount(){
		if(this.props.user.success){
			this.setState({
				Name:this.props.user.data.name,
				mobileNumber:this.props.user.data.phone
			})
			this.setState({
				formData: {
				  ...this.state.formData,
				  "entry.1205125355": this.props.user.data.name,
				  "entry.1289650525":this.props.user.data.phone
				},
			  });
			  for(let i=0;i<this.state.datesData.length;i++){
				if(this.state.datesData[i].filled == true){
					continue;
				}
				else{
					
					 if(localStorage.getItem("userSetAddress")){
					this.setState({
						formData: {
						  ...this.state.formData,
						  "entry.1205125355": this.props.user.data.name,
						  "entry.1289650525":this.props.user.data.phone,
						  "entry.1617431103":this.state.datesData[i].dateTosend,
						  "entry.145032603":JSON.parse(localStorage.getItem("userSetAddress")).address,
						},
					  });
					 
						this.setState({
							location:JSON.parse(localStorage.getItem("userSetAddress")).address
						})
					}
					  this.setState({activeDate:i})
					  break;
				}
			}
		}
		
		const response = await fetch('https://lottie.host/e2beaa18-ae29-42f7-908d-9c59174259b1/6fKqHELq6b.json');
       
        const data = await response.json();
        this.setState({ animationData: data });

		
		
	  }
	  handleChange = (event) => {
		console.log(typeof(event.target.value),"value")
		if(event.target.name == "entry.1205125355"){
			this.setState({Name:event.target.value})
		}
		if(event.target.name == "entry.1289650525"){
			this.setState({mobileNumber:event.target.value})
		}
		if(event.target.name ==  "entry.145032603"){
			this.setState({location:event.target.value})
		}
		this.setState({
		  formData: {
			...this.state.formData,
			[event.target.name]: event.target.value,
		  },
		});
	  };
	
	  handleSubmit = (event) => {
		event.preventDefault();
	  
	 
	 
	 console.log(this.state.formData['entry.1205125355'], 
	 (this.state.formData['entry.1289650525']),
	 (this.state.formData['entry.145032603']),this.state.formData['entry.1617431103'])
	 
	  if((this.state.formData['entry.1205125355'] != '' && this.state.formData['entry.1205125355'] !== undefined)&& 
	  (this.state.formData['entry.1289650525'] != '' && this.state.formData['entry.1289650525'] != undefined)&&
	  (this.state.formData['entry.332856662'] != '' && this.state.formData['entry.332856662'] != undefined)&& 
	  (this.state.formData['entry.145032603'] != '' && this.state.formData['entry.145032603'] != undefined)&&
	  ((this.state.formData['entry.435740854'] != '' && this.state.formData['entry.435740854'] != undefined) ||
	  (this.state.formData['entry.2024147669'] != '' && this.state.formData['entry.2024147669'] != undefined))&& 
	  (this.state.formData['entry.33662235'] != '' && this.state.formData['entry.33662235'] != undefined)&& 
	  (this.state.formData['entry.1617431103'] != '' && this.state.formData['entry.1617431103'] != undefined) 
	  ){

		  fetch("https://docs.google.com/forms/d/e/1FAIpQLSdMD537rRiFCyQAyb6BUn5Gt3v9bA-mUodymKIo9Km1n2FgaQ/formResponse", {
			method: "POST",
			body: new URLSearchParams(this.state.formData),
			mode: "no-cors" // Not recommended for production
		  })
			.then(() => {
			  console.log("Form submission attempted (CORS disabled)");
			  this.setState({ submitted: true });
			})
			.catch((error) => {
			  console.error("Form submission error:", error);
			});
	  }
	  else{
		this.setState({openError:true})
	  }
	  };
	  checkForItemsAvailability = () => {
		const { checkCartItemsAvailability, cartProducts, addProduct, updateCart } = this.props;
		this.handleProcessCartLoading(true);
		checkCartItemsAvailability(cartProducts).then((response) => {
			this.handleProcessCartLoading(false);
			this.setState({ process_cart_loading: false });
			if (response && response.length) {
				let isSomeInactive = false;
				response.map((arrItem) => {
					//find the item in the cart
					let item = cartProducts.find((item) => {
                        if(item.has_variant) {
                            return item.id === arrItem.id && item.selectedvariant.id === arrItem.variant_id
                        }
                        return item.id === arrItem.id
                    });

					//get new price and is_active status and set it.
					item.is_active = arrItem.is_active;
					item.price = arrItem.price;
					item.name = arrItem.name;
                    
                    if(arrItem.selectedvariant) {
                        item.selectedvariant = arrItem.selectedvariant;
                    }

					addProduct(item);

					if (!isSomeInactive) {
						if (!arrItem.is_active) {
							isSomeInactive = true;
						}
					}
					return item;
				});
				if (isSomeInactive) {
					this.handleItemsAvailability(false);
				} else {
					this.handleItemsAvailability(true);
				}
			}
			updateCart(this.props.cartProducts);
		});
	};
	handleItemsAvailability = (value) => {
		this.setState({ is_all_items_available: value });
	};
	handleProcessCartLoading = (value) => {
		this.setState({ process_cart_loading: value });
	};
	  cartOperationalSteps = () => {
		const { user } = this.props;

		this.setState({ loading: false });
		if (this.props.cartProducts.length) {
			document.getElementsByTagName("body")[0].classList.add("bg-grey");
			this.checkForItemsAvailability();
		}
		
		if (localStorage.getItem("activeRestaurant") !== null && this.props.cartProducts.length > 0) {
			this.props.getRestaurantInfoById(localStorage.getItem("activeRestaurant")).then((response) => {
				if (response) {
                    // console.log('rr', response);
					if (response.payload.id) {
						if (!user.success) {
							this.__doesRestaurantOperatesAtThisLocation(response.payload);
						}
					}
				}
			});
		}

		if (user.success) {
			this.props.checkUserRunningOrder(user.data.id, user.data.auth_token);
			if (this.props.cartProducts.length > 0) {
				this.props.updateUserInfo(user.data.id, user.data.auth_token).then((updatedUser) => {
					// console.log("THIS SHOULD BE CALLED: UPDATED USER", updatedUser);
					if (typeof updatedUser !== "undefined") {
						if (updatedUser.payload.data.default_address !== null) {
							const userSetAddress = {
								lat: updatedUser.payload.data.default_address.latitude,
								lng: updatedUser.payload.data.default_address.longitude,
								address: updatedUser.payload.data.default_address.address,
								house: updatedUser.payload.data.default_address.house,
								tag: updatedUser.payload.data.default_address.tag,
							};

							const saveUserSetAddress = new Promise((resolve) => {
								localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
								resolve("Address Saved");
							});
							saveUserSetAddress.then(() => {
                                // console.log('rr', this.props);
								this.__doesRestaurantOperatesAtThisLocation(this.props.restaurant_info);
							});
							// localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
						} else {
							this.__doesRestaurantOperatesAtThisLocation(this.props.restaurant_info);
						}
					} else {
						console.warn("Failed to fetch update user info... Solution: Reload Page");
					}
				});
			}
		} else {
			this.setState({ alreadyRunningOrders: false });
		}
	};
	  handleSetDefaultAddress = (address_id, address) => {
		const { user } = this.props;
		if (user.success) {
			this.props.setDefaultAddress(user.data.id, address_id, user.data.auth_token).then(() => {
				this.setState({ addressesLoading: true });
				const saveUserSetAddress = new Promise((resolve) => {
					const userSetAddress = {
						lat: address.latitude,
						lng: address.longitude,
						address: address.address,
						house: address.house,
						tag: address.tag,
					};
					localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
					resolve("Address Saved");
				});
				saveUserSetAddress.then(() => {
					this.setState({ addressesLoading: false });
					console.log(localStorage.getItem("fromCart"),"cartrs")
						if (localStorage.getItem("fromCart") === "1") {
						  localStorage.removeItem("fromCart");
						  this.setState({ open: false });
						  this.cartOperationalSteps();
						}
				});
			});
		}
	};
	
	__doesRestaurantOperatesAtThisLocation = (restaurant_info) => {
		//send user lat long to helper, check with the current restaurant lat long and setstate accordingly
		const { user } = this.props;

		if (user.success && user.data.default_address !== null) {
			let self = this;
			let distance = 0;
			if (localStorage.getItem("enGDMA") === "true") {
				this.setState({ process_distance_calc_loading: true });
				distance = calculateDistanceGoogle(
					restaurant_info.longitude,
					restaurant_info.latitude,
					user.data.default_address.longitude,
					user.data.default_address.latitude,
					this.props.google,
					function(distance) {
						// console.log("Distance:", distance);
                        // console.log('restautant1', restaurant_info.id)
						self.setState(
							{ distance: distance, process_distance_calc_loading: false },
							self.__processRestaurantOperationalState(
								restaurant_info.id,
								user.data.default_address.latitude,
								user.data.default_address.longitude
							)
						);
					}
				);
			} else {
				distance = calculateDistance(
					restaurant_info.longitude,
					restaurant_info.latitude,
					user.data.default_address.longitude,
					user.data.default_address.latitude
				);
                // console.log('restautant1', restaurant_info)
				this.setState(
					{ distance: distance },
					this.__processRestaurantOperationalState(
						restaurant_info.id,
						user.data.default_address.latitude,
						user.data.default_address.longitude
					)
				);
			}
		} else {
			//if Google Distance Matrix API is enabled
			let self = this;
			let distance = 0;
			if (localStorage.getItem("userSetAddress") !== null) {
				if (localStorage.getItem("enGDMA") === "true") {
					distance = calculateDistanceGoogle(
						restaurant_info.longitude,
						restaurant_info.latitude,
						JSON.parse(localStorage.getItem("userSetAddress")).lng,
						JSON.parse(localStorage.getItem("userSetAddress")).lat,
						this.props.google,
						function(distance) {
							// console.log(distance);
							self.setState(
								{ distance: distance },
								self.__processRestaurantOperationalState(
									restaurant_info.id,
									JSON.parse(localStorage.getItem("userSetAddress")).lat,
									JSON.parse(localStorage.getItem("userSetAddress")).lng
								)
							);
						}
					);
				} else {
					distance = calculateDistance(
						restaurant_info.longitude,
						restaurant_info.latitude,
						JSON.parse(localStorage.getItem("userSetAddress")).lng,
						JSON.parse(localStorage.getItem("userSetAddress")).lat
					);
					this.setState(
						{ distance: distance },
						this.__processRestaurantOperationalState(
							restaurant_info.id,
							JSON.parse(localStorage.getItem("userSetAddress")).lat,
							JSON.parse(localStorage.getItem("userSetAddress")).lng
						)
					);
				}
			} else {
				this.setState({
					is_operational: true,
					is_operational_loading: false,
				});
			}

			// console.log("Distance -> ", this.state.distance);
		}
	};
	__processRestaurantOperationalState = (id, lat, lng) => {
        // console.log('r_id', id);
		this.props.getRestaurantInfoAndOperationalStatus(id, lat, lng).then((response) => {
			if (response) {
				if (response.payload.is_operational) {
					this.setState({
						is_operational: true,
						is_operational_loading: false,
					});
				} else {
					this.setState({
						is_operational: false,
						is_operational_loading: false,
					});
				}
			}
		});
	};
	dateSlected = (id,date)=>{
		this.setState({activeDate:id})
		this.setState({
			formData: {
			  ...this.state.formData,
			  "entry.1617431103": `${date}`,
			},
		  });
	}
	render() {
        if(!this.props.user.success){
			this.context.router.history.push("/login")
		}
        const { submitted,Name,mobileNumber,location,activeDate,datesData } = this.state;
		const { addresses,user } = this.props;
		return (
			<React.Fragment>
				<button
													type="button"
													className={`btn search-navs-btns`}
													style={{ position:'fixed',top:14 ,left:10,background:'rgba(255,255,255,.3)',borderRadius:50,width:40,height:40,zIndex:2}}
													onClick={() => {
														setTimeout(() => {
															this.context.router.history.goBack();
														}, 200);
													}}
												>
													<img src="/assets/img/backSvg.svg" style={{width:20}} />
													{/* <i className="si si-arrow-left" /> */}
													<Ink duration="500" />
									</button>
				{
					submitted ? 
					<>
				
					 <div className="d-flex justify-content-center align-items-center" style={{marginTop:100}}>
						<div>
                    <Lottie style={{height:250,width:500}} animationData={this.state.animationData} loop={true} />
					
                    </div>
						</div>
						<div style={{fontSize:18,textAlign:'center',fontWeight:'bold'}}>
					Your form has been submitted. Our team will get back to you shortly.
					</div>
					</>
					:
						<div className="" style={{background:'#f0f0f5',height:'100%',width:'100%',paddingTop:50}}>
							
						<form onSubmit={this.handleSubmit}
				style={{ display: submitted ? 'none' : 'block' }} 
		>
			<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}} for="entry.1205125355">Name</label><br/>
		<input value={Name} onChange={this.handleChange} 
		style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}}  
		type="text" name="entry.1205125355" placeholder="Your Name"/><br/>
			</div>

		<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}}  for="entry.1289650525">Mobile Number</label><br/>
		<input value={mobileNumber} onChange={this.handleChange} 
		style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}} 
		type="tel" name="entry.1289650525" placeholder="Mobile Number"/><br/>
		</div>

		<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}} for="entry.332856662">Alternative Mobile Number</label><br/>
		<input onChange={this.handleChange} style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}} type="text" name="entry.332856662" placeholder="Alternative Mobile Number"/><br/>
		</div>

		<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
			<div className="d-flex justify-content-between align-items-center">
				<div>
				<label style={{fontSize:16}} for="entry.145032603">Location</label><br/>
				</div>
				<div style={{color:'rgb(27, 166, 114)',fontWeight:600}} onClick={()=>{this.setState({open:true})
			localStorage.setItem("fromCart", 1);}}>
					Change
				</div>
			</div>
		<input value={location} onChange={this.handleChange} style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}} type="text" name="entry.145032603" placeholder="Location"/><br/>
		</div>

		<div className="m-15 p-15 d-flex justify-content-between align-items-center" style={{background:'white',borderRadius:16}}>
		<label  style={{fontSize:16}} for="entry.435740854">Number Of Adults</label><br/>
		<div className="d-flex px-10 py-5 align-items-center" style={{width:82,height:32,boxShadow:'rgba(40, 44, 63, 0.08) 0px 3px 8px',borderRadius:8,border:'1px solid rgba(2, 6, 12, 0.15)',background:'white'}}>
		<div style={{width:27,color:'rgb(27, 166, 114)',fontWeight:600,fontSize:18}} onClick={()=>{
			if(this.state.numberAdults > 0){
				this.setState({numberAdults:this.state.numberAdults-1})
				this.setState({
					formData: {
					  ...this.state.formData,
					  "entry.435740854": this.state.numberAdults-1,
					},
				  })
			}
			}}>-</div>
			
		<input className="V_J6V" onChange={this.handleChange} 
		style={{border:'none',width:27,color:'black',fontWeight:600,fontSize:18}} 
		type="text" value={this.state.numberAdults} name="entry.435740854" placeholder=""/><br/>

		<div style={{width:27,color:'rgb(27, 166, 114)',fontWeight:600,fontSize:18}} onClick={()=>{this.setState({numberAdults:this.state.numberAdults+1})
	this.setState({
		formData: {
		  ...this.state.formData,
		  "entry.435740854": this.state.numberAdults+1,
		},
	  })
	
	}}>+</div>
		</div>
		</div>

		<div  className="m-15 p-15 d-flex justify-content-between align-items-center" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}} for="entry.2024147669">Number Of Kids</label><br/>
		<div className="d-flex px-10 py-5 align-items-center" style={{background:'white',width:82,height:32,boxShadow:'rgba(40, 44, 63, 0.08) 0px 3px 8px',borderRadius:8,border:'1px solid rgba(2, 6, 12, 0.15)'}}>
		<div style={{width:27,color:'rgb(27, 166, 114)',fontWeight:600,fontSize:18}} onClick={()=>{
			if(this.state.numberKids > 0){
				this.setState({numberKids:this.state.numberKids-1})
				this.setState({
					formData: {
					  ...this.state.formData,
					  "entry.2024147669": this.state.numberKids-1,
					},
				  })
			}
			}}>-</div>
			
		<input className="V_J6V" onChange={this.handleChange} 
		style={{border:'none',width:27,color:'black',fontWeight:600,fontSize:18}} 
		type="text" value={this.state.numberKids} name="entry.435740854" placeholder=""/><br/>
		<div style={{width:27,color:'rgb(27, 166, 114)',fontWeight:600,fontSize:18}} onClick={()=>{this.setState({numberKids:this.state.numberKids+1})
	this.setState({
		formData: {
		  ...this.state.formData,
		  "entry.2024147669": this.state.numberKids+1,
		},
	  })
	
	}}>+</div>
		</div>
		</div>
		<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}} for="entry.33662235">How do you know about us</label><br/>
		<input onChange={this.handleChange} style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}} 
		type="text" name="entry.33662235" placeholder=""/><br/>
		</div>

		<div className="m-15 p-15" style={{background:'white',borderRadius:16}}>
		<label style={{fontSize:16}} for="entry.1617431103">Visiting Date</label><br/>
		<div style={{height:110,overflow:'scroll'}} className="d-flex">
		{/* f15700 */}
			{datesData.map((data,index)=>
			<div key={index} className="mr-10 d-flex justify-content-center align-items-center" 
			style={{border:activeDate == index && !data.filled ? `3px solid ${localStorage.getItem("storeColor")}` :'3px solid #f0f0f5',
			borderRadius:16,padding:8,
			background:activeDate == index && !data.filled ?'rgba(8, 157, 73,.1)':'white' | data.filled && '#f0f0f5'
			,width:90,position:'relative',height:84}}
		
			onClick={()=>{
				if(!data.filled){
					this.dateSlected(index,data.dateTosend)
				}
				}}>
				<div style={{width:90}}>
				<div style={{fontSize:14,textAlign:'center',color:activeDate == index && !data.filled ? localStorage.getItem("storeColor") :'black'}}>{data.day}</div>
				<div style={{fontWeight:600,textAlign:'center',color:activeDate == index && !data.filled ? localStorage.getItem("storeColor") :'black',fontSize:16}}>{data.date}</div>
				{
					data.filled &&
						<div style={{textAlign:'center',
							color:'black',fontSize:14,
							position:'absolute',bottom:-8,left:4,borderRadius:26,
							background:'red',color:'white',border:'2px solid red',padding:'0px 4px',
							width:'90%',height:20}}>
						Filled
						</div>
				}
				</div>
			</div>
			)}
		</div>
		{/* <input onChange={this.handleChange} style={{border:'none',borderBottom:'1px solid #f0f0f5',width:'100%'}} type="date" name="entry.1617431103" placeholder=""/><br/> */}
		</div>
		<button type="submit" className="d-flex justify-content-center"  style={{padding:'0px 0px 30px 0px',border:'none',width:'100%',background:'#f0f0f5'}}>
		{/* to="/farm-visit" */}
								<div  className="get-card-button" style={{backgroundColor:localStorage.getItem("storeColor")}}>
									Submit Form
								</div>
								</button>
		</form>
		<Modal open={this.state.open} onClose={()=>this.setState({open:false})} closeIconSize={32}>
					<div className="p-15"
						style={{
							textAlign: "left",
							height:633,
						}}
					>
						<h4 className="mb-2" style={{fontWeight:'bold',position:'sticky',top:0}}>Where are you coming from</h4>
						<React.Fragment>
				{this.state.addressesLoading ? (
					<Loading />
				) : (
					<React.Fragment>
						<Link
							to="/search-location"
							className="d-flex align-items-center"
							style={{
								color: localStorage.getItem("storeColor"),
								border:'none',
								fontSize:16,
								fontWeight:500,
								margin:'16px 0px'
							}}
						>
						<div className="d-flex align-items-center justify-content-center mr-4"  
						style={{border:`1px solid ${localStorage.getItem("storeColor")}`,
						borderRadius:8,width:40,height:28,fontSize:20}} >+</div>	
						<div>Add {localStorage.getItem("buttonNewAddress")}</div>
							
						</Link>
						<div style={{height:600,overflowY:'scroll'}}>
							{addresses.length === 0 && !this.state.no_address && (
								<ContentLoader
									height={600}
									width={400}
									speed={1.2}
									primaryColor="#f3f3f3"
									secondaryColor="#ecebeb"
								>
									<rect x="0" y="0" rx="0" ry="0" width="75" height="22" />
									<rect x="0" y="30" rx="0" ry="0" width="350" height="18" />
									<rect x="0" y="60" rx="0" ry="0" width="300" height="18" />
									<rect x="0" y="90" rx="0" ry="0" width="100" height="18" />

									<rect x="0" y={0 + 170} rx="0" ry="0" width="75" height="22" />
									<rect x="0" y={30 + 170} rx="0" ry="0" width="350" height="18" />
									<rect x="0" y={60 + 170} rx="0" ry="0" width="300" height="18" />
									<rect x="0" y={90 + 170} rx="0" ry="0" width="100" height="18" />

									<rect x="0" y={0 + 340} rx="0" ry="0" width="75" height="22" />
									<rect x="0" y={30 + 340} rx="0" ry="0" width="350" height="18" />
									<rect x="0" y={60 + 340} rx="0" ry="0" width="300" height="18" />
									<rect x="0" y={90 + 340} rx="0" ry="0" width="100" height="18" />
								</ContentLoader>
							)}

							{addresses.length ? (
								addresses.map((address) => (
									<AddressList
										handleDeleteAddress={this.handleDeleteAddress}
										deleteButton={false}
										key={address.id}
										address={address}
										user={user}
										fromCartPage={false}
										handleSetDefaultAddress={this.handleSetDefaultAddress}
										fromPage="cart"
									/>
								))
							) : (
								<div className="text-center mt-50 font-w600 text-muted">
									{localStorage.getItem("noAddressText")}
								</div>
							)}
						</div>
						
					</React.Fragment>
				)}
			</React.Fragment>
					</div>
				</Modal>
				<Dialog
					fullWidth={true}
					fullScreen={false}
					open={this.state.openError}
					onClose={()=>{this.setState({ openError: false })}}
					style={{ width: "380", padding:10 }}
					PaperProps={{ style: { backgroundColor: "white", borderRadius: "24px",width:'100%',margin:0 } }}
				>
					<div className="d-flex justify-content-between" style={{padding:30}}>
					<div style={{color:'red',fontSize:20}}>
					Please fill all the details
					</div>
					<div >

					<button onClick={()=>{this.setState({openError:false})}} className="btn btn-lg mr-15"
								style={{ border: "0", borderRadius: 12,backgroundColor: localStorage.getItem("storeColor")}}>
						Ok
					</button>
					</div>
					</div>
					</Dialog>
						</div>

				}
			</React.Fragment>
		);
	}
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
	user: state.user.user,
	addresses: state.addresses.addresses,
	cartProducts: state.cart.products,
	restaurant_info: state.items.restaurant_info,
});

export default withRouter(
	connect(
		mapStateToProps,
		{
			getAddresses,
			saveAddress,
			deleteAddress,
			setDefaultAddress,
			getRestaurantInfoById,
			getRestaurantInfoAndOperationalStatus,
			checkUserRunningOrder,
			updateUserInfo,
			checkBan,
			checkCartItemsAvailability,
			addProduct,
			updateCart
		}
	)(FarmVisitForm)
);
