import { GET_DYNAMIC_FEATURED_SECTION, GET_DYNAMIC_FEATURED_URL_SECTION } from "./actionTypes";

const initialState = {
    dynamic_featured_sections: [],
    dynamic_featured_url_section: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DYNAMIC_FEATURED_SECTION:
            return { ...state, dynamic_featured_sections: action.payload };
        case GET_DYNAMIC_FEATURED_URL_SECTION:
            return { ...state, dynamic_featured_url_section: action.payload };
        default:
            return state;
    }
}
