import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Ink from "react-ink";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Zoom from "@material-ui/core/Zoom";
class AddressList extends Component {
	state = {
		dropdownItem: null,
		opendelpopup: false
	};

	handleSetDefaultAddress = (e, address) => {
		if (!e.target.classList.contains("si")) {
			this.props.handleSetDefaultAddress(address.id, address);
		}
	};

	handleDropdown = (event) => {
		this.setState({ dropdownItem: event.currentTarget });
	};

	handleDropdownClose = () => {
		this.setState({ dropdownItem: null });
	};

	render() {
		const { user, address,fromPage } = this.props;
		return (
			<React.Fragment>
				<div
					className={
						!address.is_operational && this.props.fromCartPage
							? "bg-white single-address-card d-flex no-click"
							: "bg-white single-address-card d-flex"
					}
					onClick={(e) => this.handleSetDefaultAddress(e, address)}
					style={{ position: "relative",padding: this.props.fromPage == 'cart' && '16px 0px',
					border: this.props.fromPage == 'cart' && 'none',
				    borderBottom: this.props.fromPage == 'cart' && '1px solid rgb(226, 226, 231)',
					borderRadius: this.props.fromPage == 'cart' && 0
				 }}
				>
					{this.props.fromPage == 'cart' &&
						<div className="mr-3">
							<img style={{border:'1px solid rgb(226, 226, 231)',padding:10,borderRadius:8}} className="mb-2" src="assets\img\locationArrow.svg" />
						</div>
					}
					<div
						className={
							!address.is_operational && this.props.fromCartPage ? "address-not-usable w-100" : "w-100"
						}
					>
						{user.data.default_address_id === address.id ? (
							<button
								className="btn btn-sm pull-right btn-address-default p-0 m-0"
								style={{ border: "0", right: "0px", fontSize: "1.3rem" }}
							>
								<i
									className="si si-check"
									style={{
										color: localStorage.getItem("storeColor"),
									}}
								/>
								<Ink duration={200} />
							</button>
						) : (
							<React.Fragment>
								{this.props.fromCartPage && (
									<React.Fragment>
										{!address.is_operational && (
											<span className="text-danger text-uppercase font-w600 text-sm08">
												{" "}
												<i className="si si-close" />{" "}
												{localStorage.getItem("addressDoesnotDeliverToText")}
											</span>
										)}
									</React.Fragment>
								)}
							</React.Fragment>
						)}

						{address.tag !== null && (
							<h6 className="m-0 text-uppercase" style={{color: 'rgb(54, 57, 62)',
								fontSize: 16}}>
									{this.props.fromPage !== 'cart' &&
										<img className="mb-2" src="assets\img\locationArrow.svg" />
									}
									<strong>{address.tag}</strong>
							</h6>
						)}
						<div>
							{address.house !== null ? (
								<span className="m-0" style={{color: 'rgb(102, 104, 110)',
									fontSize: 14}}>
									{address.tag === null ? (
										<>
										{this.props.fromPage !== 'cart' &&
										<img className="mb-2" src="assets\img\locationArrow.svg" />
										}
									<strong className="ml-1">{address.house}</strong>
										<p className="m-0" style={{color: 'rgb(102, 104, 110)',
											fontSize:this.props.fromPage == 'cart'? 13 : 14,
											lineHeight:this.props.fromPage == 'cart' ? '16px' : '18px',
											letterSpacing: '-0.02px',
											fontFamily:'Basis_Grotesque_Regular',margin:0}}>
									{address.address}
									</p>
										</>
									) : (
										<p  style={{color: 'rgb(102, 104, 110)',
										fontSize:this.props.fromPage == 'cart'? 13 : 14,
										lineHeight:this.props.fromPage == 'cart' ? '16px' : '18px',
										letterSpacing: '-0.02px',
										fontFamily:'Basis_Grotesque_Regular',margin:0}}>
											{address.house},{address.address}
										</p>
									)}
								</span>
							)
						:
						<p className="m-0 "style={{color: 'rgb(102, 104, 110)',
						fontSize:this.props.fromPage == 'cart'? 13 : 14,
						lineHeight:this.props.fromPage == 'cart' ? '16px' : '18px',
						letterSpacing: '-0.02px',
						fontFamily:'Basis_Grotesque_Regular',margin:0}}>
						{address.address}
						
						</p>
						
						}
							
						</div>
					</div>

					<div>
						{!this.props.fromCartPage && this.props.deleteButton && (
							<React.Fragment>
								{user.data.default_address_id !== address.id && (
									<button
										className="btn btn-sm pull-right btn-address-default p-0 m-0 btn-delete-address"
										style={{ border: "0", right: "0px", fontSize: "1.3rem", zIndex: 2 }}
									>
										<i
											className="si si-trash"
											style={{ fontSize: "1.3rem" }}
											// this.handleDropdown
											onClick={()=>this.setState({opendelpopup:true})}
										/>
									</button>
								)}
							</React.Fragment>
						)}
					</div>
					<Ink duration={500} hasTouch={true} />
				</div>
				<Menu
					id="simple-menu"
					keepMounted
					anchorEl={this.state.dropdownItem}
					open={Boolean(this.state.dropdownItem)}
					onClose={this.handleDropdownClose}
					TransitionComponent={Zoom}
					MenuListProps={{ disablePadding: true }}
					elevation={3}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<MenuItem
						onClick={() => {
							this.props.handleDeleteAddress(address.id);
							this.handleDropdownClose();
						}}
					>
						{localStorage.getItem("deleteAddressText")}
					</MenuItem>
				</Menu>
				<Dialog
					fullWidth={true}
					fullScreen={false}
					open={this.state.opendelpopup}
					onClose={()=>{this.setState({ opendelpopup: false })}}
					style={{ width: "380", padding:10 }}
					PaperProps={{ style: { backgroundColor: "white", borderRadius: "24px",width:'100%',margin:0 } }}
				>
					<div className="mt-20" style={{textAlign:'center'}}>

					<i className="si si-trash logout-icon" style={{ fontSize: "4rem",color: "red" }}/>
					<p style={{color:'rgba(2, 6, 12, 0.92)',fontSize:20}}>
						Are you sure?
					</p>
					</div>
					<div className="m-15 d-flex justify-content-between">

						<button
								onClick={()=>this.setState({opendelpopup:false})}
								className="btn btn-lg  mr-3"
								style={{ border: "0", borderRadius: 12 }}
							>
								{localStorage.getItem("cancelGoBackBtn")}
							</button>
							<button
								className="btn btn-lg btn-danger"
								onClick={() => {
									this.props.handleDeleteAddress(address.id);
									this.handleDropdownClose();
								}}
								style={{
									border: "0",
									borderRadius: 12,
									// backgroundColor: localStorage.getItem("storeColor")
								}}
							>
								{localStorage.getItem("deleteAddressText")}
							</button>
						</div>
				</Dialog>
			</React.Fragment>
		);
	}
}

export default AddressList;
