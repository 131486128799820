import { CONFIRM_CART, CONFIRM_MEMBER_CART } from "./actionTypes";

const initialState = {
    confirmCart: false,
    confirmMemberCart: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CONFIRM_CART:
            return { ...state, confirmCart: action.payload };
        case CONFIRM_MEMBER_CART:
            return { ...state, confirmMemberCart: action.payload };
        default:
            return state;
    }
}
