import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazyload";

import { withRouter } from "react-router-dom";
// import Customization from "../../CategoryItemsV1/Customization";
import FloatCart from "../../FloatCart";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getRestaurantsFeaturedItems } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import Variations from "../../CategoryItemsV1/Variations";
import Customization from "../../Items/Customization";
import { setFavoriteItem } from "../../../../services/items/actions";
import MemberVariants from "../../MembersMart/MemberVariants";
import zIndex from "@material-ui/core/styles/zIndex";

class FrequentlyBoughtList extends Component {
	state = {
		update: true,
		total: null,
		restaurants_featured_items: [],
		loading: false,
		loading_more: true,
		no_restaurants_featured_items: false
	};

	componentDidMount() {
		this.__getRestaurantsFeaturedItems();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState !== this.state.data) {
			return true;
		} else {
			return false;
		}
	}

	__getRestaurantsFeaturedItems = () => {
		if (localStorage.getItem("userSetAddress")) {
			this.setState({
				loading: true,
			});
			const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
			this.props.getRestaurantsFeaturedItems(userSetAddress.lat, userSetAddress.lng).then((restaurants_featured_items) => {
				if (restaurants_featured_items && restaurants_featured_items.payload.length) {
					this.setState({
						total: restaurants_featured_items.payload.length,
						no_restaurants_featured_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_restaurants_featured_items: true,
					});
				}
			});
		}
	};

	forceStateUpdate = () => {
        this.forceUpdate();
        const restaurant = this.props.match.params.restaurant;

        if(restaurant !== 'all') {
            this.props.forceStateUpdate();
        }
		setTimeout(() => {
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
	};

    __getSalePrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.sale_price !== undefined) {
            let sale_price = variant.sale_price;
            return parseFloat(sale_price).toFixed(0);
        }
        return 0;
    }

    __getRegularPrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.regular_price !== undefined) {
            let sale_price = variant.sale_price;
            let regular_price = variant.regular_price;
            if(sale_price !== regular_price) {
                return parseFloat(regular_price).toFixed(0);   
            }
        }
        return false;
    }

    __getDiscountPercentage = (item) => {
        var discount = 0;
        if(item.has_variant) {
            let sale_price = this.__getSalePrice(item.variants);
            let regular_price = this.__getRegularPrice(item.variants);
            if(parseFloat(sale_price) >= parseFloat(regular_price)) {
                return false;
            }
            discount = ( ( parseFloat(regular_price) - parseFloat(sale_price) ) / parseFloat(regular_price) ) * 100;
        }
        return discount !== 0 ? discount.toFixed(0) : false;
    }

	setFavoriteItem = (single_item, event) => {
		const { user } = this.props;
		if (user.success) {
			event.target.classList.toggle("is-active");
			this.props.setFavoriteItem(user.data.auth_token, single_item.id).then(res => this.props.updateRestaurantItemsState(true));
		}
	};

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item) => {
        if(!item.has_variant) return true;

        if(item.variant_type === "P") {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if(is_item_out_of_stock.length > 0) { return true; }
            return item.variant_stock > 0;
        }
    }

	render() {
		const { addProduct, removeProduct, cartProducts, forceRestaurantItemsUpdate } = this.props;

        const isItemExistInCart = (item_id) => {
            let cc = cartProducts.find(cartItem => {
                return cartItem.id === item_id
            });
            if(cc !== undefined) {
                return true;
            }
            return false;
        }

		const { history, user } = this.props;
        const restaurant = this.props.match.params.restaurant;

        if(restaurant !== 'all') {
            this.props.forceStateUpdate();
        }

        if(forceRestaurantItemsUpdate === true) {
            const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
			this.props.getRestaurantsFeaturedItems(userSetAddress.lat, userSetAddress.lng).then((restaurants_featured_items) => {
				if (restaurants_featured_items && restaurants_featured_items.payload.length) {
					this.setState({
						total: restaurants_featured_items.payload.length,
						no_restaurants_featured_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_restaurants_featured_items: true,
					});
				}
			});
            this.props.updateRestaurantItemsState(false);
        }

		return (
			<React.Fragment>
                <div className="bg-white">                    
                    { restaurant !== undefined ? (
                        <React.Fragment>                         
                            <BackWithSearch
                                ref={(node) => {
                                    this.child = node;
                                }}
                                history={history}
                                boxshadow={false}
                                has_restaurant_info={true}
                                restaurant={restaurant}
                                disable_search={true}
                                homeButton={false}
                                shareButton={true}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
                {
                    this.props.restaurants_featured_items.length !== 0  &&
                    <div className={" bg-white mb-30" + (restaurant !== undefined ? ' pt-50' : '')}>
                    <div>
                            <img style={{width:'100%'}} src="/assets/img/summerBanner.avif" />
                        </div>
                        {this.state.loading ? (
                            <ContentLoader
                                height={378}
                                width={400}
                                speed={1.2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#ecebeb"
                            >
                                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                                <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                                <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                                <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                                <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                                <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                                <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                                <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                            </ContentLoader>
                        ) : (
                            <React.Fragment>
                                <div className="frequently-bought-title-section pt-30 px-15 pb-0" style={{background:'white'}}>                                
                                    <span className="title"><strong style={{fontSize:18}}>{localStorage.getItem('frequentlyBoughtSectionTitle')}</strong></span>
                                    <span className="subtitle">{localStorage.getItem('frequentlyBoughtSectionSubTitle')}</span>

                                    { restaurant !== undefined ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <DelayLink
                                            to={"frequently-bought-items/all"}
                                            delay={200}
                                            className="block text-center mb-3 mr-3 view-all"
                                        >
                                            {/* View All
                                            <i
                                                className="si si-arrow-right nav-location-icon ml-1"
                                            /> */}
                                        </DelayLink>
                                    )}
                                </div>
                                <div className="frequently-bought-content-section">
                                    {this.props.restaurants_featured_items.length === 0 ? (
                                        <></>
                                        // <ContentLoader
                                        //     height={378}
                                        //     width={400}
                                        //     speed={1.2}
                                        //     primaryColor="#f3f3f3"
                                        //     secondaryColor="#ecebeb"
                                        // >
                                        //     <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                        //     <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                                        //     <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                                        //     <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                                        //     <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                                        //     <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                                        //     <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                                        //     <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                                        //     <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                                        // </ContentLoader>
                                    ) : (
                                        <React.Fragment>
                                            <div className="p-3" style={{background:'white'}}>

                                        {this.props.restaurants_featured_items.map((item) => (
                                            <>
                                            {
                                                item.is_free_item !== 1 &&
                                                    <React.Fragment key={item.id}>
                                                    
                                                        <div style={{ 
                                                            position: 'relative'
                                                            }}>
                                                            {!this.__itemAvailability(item) && (
                                                                <div className="blur-content-list">{localStorage.getItem('outOfStockText')}</div>
                                                            )}
                                                            <div className={`category-list-item mb-3 ${!this.__itemAvailability(item) ? ('blur-content-section') : ''}`}
                                                                style={{
                                                                    display: "flex",
                                                                    borderRadius: 16,
                                                                    padding: 10,
                                                                    // boxShadow: 'rgba(40, 44, 63, 0.1) 0px 2px 8px',
                                                                    background:'white',
                                                                    alignItems:'center',
                                                                    border:'1px solid #f2f3f3'
                                                                }}>
                                                                    <div className="item-ribbon text-left"
                                                                                    style={{
                                                                                        visibility: !this.__getDiscountPercentage(item) || this.__getDiscountPercentage(item) == 'NaN' ? "hidden" : "" ,
                                                                                        position:'absolute',
                                                                                        top:0,
                                                                                        left:0,
                                                                                        display:'flex',
                                                                                        justifyContent:'center',
                                                                                        alignItems:'center',
                                                                                        zIndex:2
                                                                                    }}

                                                                                >
                                                                            
                                                                                    <img style={{width:30,height:32,borderTopLeftRadius:10}} src="/assets/img/offer_tag.avif" />
                                                                                    <span style={{position:'absolute',top:0,left:0,fontWeight: 600,fontSize: 12,
                                                                                    lineHeight:'11px',letterSpacing: '-0.4px',color:'white',padding:4,textAlign:'center'}}>
                                                                                    
                                                                                    {this.__getDiscountPercentage(item) ? this.__getDiscountPercentage(item) : '&nbsp;'}%
                                                                                    <br/>
                                                                                    OFF
                                                                                    </span>
                                                                                </div>
                                                                {/* {this.__getDiscountPercentage(item) ? (
                                                                    <div className="item-discount-tag">
                                                                        <img alt="item-discount" src="../../assets/img/various/discount-ribbon.png" />
                                                                        <span className="discount-price">{ this.__getDiscountPercentage(item) }% OFF</span>
                                                                    </div>
                                                                ) : (
                                                                    <React.Fragment />
                                                                )} */}
                                                                {item.image !== null && (
                                                                    <React.Fragment>
                                                                        <Link to={"../stores/" + item.restaurant_slug + "/" + item.id} style={{ width: "30%"}}>
                                                                            <React.Fragment>
                                                                                {this.state.searching ? (
                                                                                    <img
                                                                                    
                                                                                        src={item.image}
                                                                                        alt={item.name}
                                                                                        className="flex-item-image"
                                                                                    />
                                                                                ) : (
                                                                                    <LazyLoad>
                                                                                        <ProgressiveImage
                                                                                            src={item.image}
                                                                                            placeholder="/assets/img/various/blank-white.jpg"
                                                                                        >
                                                                                            {(src, loading) => (
                                                                                                <img
                                                                                            
                                                                                                    style={{
                                                                                                        opacity: "1",
                                                                                                        width: 75,
                                                                                                        borderRadius: "8%",
                                                                                                        inset: "0px",
                                                                                                    }}
                                                                                                    src={src}
                                                                                                    alt={item.name}
                                                                                                    className="flex-item-image"
                                                                                                />
                                                                                            )}
                                                                                        </ProgressiveImage>
                                                                                    </LazyLoad>
                                                                                )}
                                                                                
                                                                            </React.Fragment>
                                                                            {localStorage.getItem("showVegNonVegBadge") === "true" &&
                                                                                item.is_veg !== null && (
                                                                                    <React.Fragment>
                                                                                        {item.is_veg ? (
                                                                                            <img
                                                                                                src="/assets/img/various/veg-icon-bg.png"
                                                                                                alt="Veg"
                                                                                                className="mr-1 veg-non-veg-badge"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src="/assets/img/various/non-veg-icon-bg.png"
                                                                                                alt="Non-Veg"
                                                                                                className="mr-1 veg-non-veg-badge"
                                                                                            />
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </Link>
                                                                    </React.Fragment>
                                                                )}
                                                                <div style={{width:'70%'}}>
                                                                <Link className="flex-item-name " to={"../stores/" + item.restaurant_slug + "/" + item.id}>
                                                                    <div
                                                                        className={
                                                                            item.image !== null ? "flex-item-name " : "flex-item-name"
                                                                        }
                                                                    >
                                                                        {item.image === null && (
                                                                            <React.Fragment>
                                                                                {localStorage.getItem("showVegNonVegBadge") === "true" &&
                                                                                    item.is_veg !== null && (
                                                                                        <React.Fragment>
                                                                                            {item.is_veg ? (
                                                                                                <img
                                                                                                    src="/assets/img/various/veg-icon-bg.png"
                                                                                                    alt="Veg"
                                                                                                    className="mr-1 veg-non-veg-badge-noimage"
                                                                                                />
                                                                                            ) : (
                                                                                                <img
                                                                                                    src="/assets/img/various/non-veg-icon-bg.png"
                                                                                                    alt="Non-Veg"
                                                                                                    className="mr-1 veg-non-veg-badge-noimage"
                                                                                                />
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                            </React.Fragment>
                                                                        )}
                                                                        <div className="item-name" style={{width:'100%'}}>
                                                                            {/* {item.name.length > 18 ? (
                                                                                <>
                                                                                    {item.name.substring(0, 30)} {item.name.length < 31 && <>... </>}
                                                                                    <br/>{item.name.substring(30, 60)}{item.name.length > 60 && <>... </>}
                                                                                    {item.name}
                                                                                </>
                                                                            ) : (
                                                                                item.name
                                                                            )} */}
                                                                            {item.name}
                                                                        </div>
                                                                        {/* {item.has_variant ? (                                                    
                                                                            <span className="item-subtitle text-normal" style={{ 
                                                                                visibility: "hidden"
                                                                            }}>                                                                
                                                                                {item.variants.find(function(o) { return o.is_default === 1 } ).name}
                                                                            </span>
                                                                        ) : (                                                
                                                                            <span className="item-subtitle text-normal">&nbsp;</span>
                                                                        )} */}
                                                                        {/* <ItemBadge item={item} /> */}
                                                                        {/* <div className="item-color-price font-w600 text-left mt-5">   
                                                                            {item.has_variant ? (       
                                                                                <React.Fragment>
                                                                                    <div style={{display:"flex"}}>
                                                                                    <div style={{color:"rgb(54, 57, 62)", marginRight:"10px" , font:"bold"}} className="sale-price">                                                                
                                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                            localStorage.getItem("currencyFormat")}{" "}
                                                                                            {this.__getSalePrice(item.variants)}
                                                                                    </div>     
                                                                                    {this.__getDiscountPercentage(item) ? (
                                                                <div>
                                                                        
                                                                        <span style={{position:"relative",top:"2px",color:"rgb(151, 123, 13)"}}> ({this.__getDiscountPercentage(item)}%off)</span>
                                                                        </div>
                                                                ) : (
                                                                    <React.Fragment />
                                                                )}
                                                                                    </div>
                                                                                
                                                                                    {this.__getRegularPrice(item.variants) && (                                          
                                                                                        <span className="regular-price">       
                                                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                                localStorage.getItem("currencyFormat")}
                                                                                                <s>{this.__getRegularPrice(item.variants)}</s>
                                                                                        </span>  
                                                                                    )}
                                                                                </React.Fragment>                                             
                                                                            ) : (                                                    
                                                                                <span className="sale-price" style={{color:"rgb(54, 57, 62)", marginRight:"10px" , font:"bold"}}>
                                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                        localStorage.getItem("currencyFormat")}{" "}
                                                                                        {this.__formatPrice(item.price)}
                                                                                    {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                                                        localStorage.getItem("currencyFormat")}
                                                                                </span>
                                                                            )}     
                                                                        </div> */}
                                                                        {/* <div className="inclusive-tax-text">{localStorage.getItem('inclusiveTaxText')}</div> */}
                                                                    </div>
                                                                    <div className="item-actions pb-0 mt-5">                                                    
                                                                    {/* {user.success && (
                                                                        <span
                                                                            onClick={(event) => this.setFavoriteItem(item, event)}
                                                                            className={`item-favorite text-right heart ${item.is_favorited && "is-active"} heartIcon` + item.id}
                                                                        />
                                                                    )} */}
                                                                    
                                                                    
                                                                    {item.addon_categories.length > 0 && (
                                                                        <React.Fragment>
                                                                            <span
                                                                                className="customizable-item-text d-block text-center"
                                                                                style={{
                                                                                    color: localStorage.getItem("storeColor"),
                                                                                }}
                                                                            >
                                                                                {localStorage.getItem("customizableItemText")}
                                                                            </span>
                                                                            <br />
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                                </Link>
                                                                {item.is_active ? (
                                                                        <React.Fragment>
                                                                            {isItemExistInCart(item.id) ? (
                                                                                <React.Fragment>
                                                                                    {item.has_variant && item.variants.length > 0 ? (
                                                                                        // <Variations
                                                                                        
                                                                                        //     product={item}
                                                                                        //     addProduct={addProduct}
                                                                                        //     cartProducts={cartProducts}
                                                                                        //     forceUpdate={this.forceStateUpdate}
                                                                                        //     addByDefault={true}
                                                                                        //     showDefaultVariant={true}
                                                                                        // />
                                                                                        <MemberVariants
                                                                                product={item}
                                                                                addMemberProduct={addProduct}
                                                                                cartMemberProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                doForceUpdate={this.state.update}
                                                                                showDefaultVariant={true}
                                                                                itemType="FBItem"
                                                                                hideAdd={false}
                                                                            />
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="btn-group btn-group-sm"
                                                                                                role="group"
                                                                                                aria-label="btnGroupIcons1"
                                                                                                
                                                                                            >
                                                                                                {item.addon_categories.length ? (
                                                                                                    <button
                                                                                                        disabled
                                                                                                        type="button"
                                                                                                        className="btn btn-add-remove"
                                                                                                        style={{color:'green',background:'none',border:'none !important'}}
                                                                                                    >
                                                                                                        <span className="btn-dec">-</span>
                                                                                                        <Ink duration="500" />
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-add-remove"
                                                                                                        style={{color:'green',background:'none',border:'none  !important'}}
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            removeProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="btn-dec">-</span>
                                                                                                        <Ink duration="500" />
                                                                                                    </button>
                                                                                                )}

                                                                                                <input type="text" className="textbox-qty" disabled value={ cartProducts.find(
                                                                                                        (cp) =>
                                                                                                            cp.id ===
                                                                                                            item.id
                                                                                                    ).quantity} />
                                
                                                                                                {item.addon_categories.length ? (
                                                                                                    <Customization
                                                                                                        product={item}
                                                                                                        addProduct={addProduct}
                                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-add-remove"
                                                                                                        style={{color:'green',background:'none',border:'none !important'}}
                                                                                                        onClick={() => {
                                                                                                            console.log("add")
                                                                                                            addProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="btn-inc">+</span>
                                                                                                        <Ink duration="500" />
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    {item.has_variant && item.variants.length > 0 ? (
                                                                                        // <Variations
                                                                                        //     product={item}
                                                                                        //     addProduct={addProduct}
                                                                                        //     cartProducts={cartProducts}
                                                                                        //     forceUpdate={this.forceStateUpdate}
                                                                                        //     addByDefault={true}
                                                                                        //     showDefaultVariant={true}
                                                                                        // />
                                                                                        <MemberVariants
                                                                                        product={item}
                                                                                        addMemberProduct={addProduct}
                                                                                        cartMemberProducts={cartProducts}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        doForceUpdate={this.state.update}
                                                                                        showDefaultVariant={true}
                                                                                        itemType="FBItem"
                                                                                        hideAdd={false}
                                                                                    />
                                                                                    ): (
                                                                                        <React.Fragment  style={{
                                                                                            color: "#FFF",
                                                                                                    textTransform: "uppercase",
                                                                                                    border: "none",
                                                                                                    fontSize: "10pt",
                                                                                                    width:"100%"
                                                                                        }}
                                                                                        >
                                                                                            <div
                                                                                                className="btn-group btn-group-sm"
                                                                                                role="group"
                                                                                                aria-label="btnGroupIcons1"
                                                                                            
                                                                                            >
                                                                                                {item.addon_categories.length ? (
                                                                                                    <Customization
                                                                                                        product={item}
                                                                                                        addProduct={addProduct}
                                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-add-remove btn-custom-add"
                                                                                                        style={{color:'green',background:'none',border:'none',width:180}}
                                                                                                        onClick={() => {
                                                                                                            console.log("add")
                                                                                                            addProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}
                                                                                                    >
                                                                                                        Add
                                                                                                        <Ink duration="500" />
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <div className="text-danger text-item-not-available">
                                                                            {localStorage.getItem("cartItemNotAvailable")}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                            }
                                            </>
                                        ))}
                                        <DelayLink
                                            to={"frequently-bought-items/all"}
                                            >
                                            <button
                                        type="submit"
                                        className="btn btn-main"
                                        style={{
                                            backgroundColor:`${localStorage.getItem("storeColor")}`,
                                                width: "100%",
                                                borderRadius: 12,
                                                color:'white',
                                                fontSize: 18,
                                                height:'3rem'
                                        }}
                                        
                                    >
                                        View All
                                    </button>
                                            </DelayLink>
                                            </div>
                                        </React.Fragment>                                    
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    
                        <div>
                            {this.props.match.params.restaurant === 'all' ? (
                                <React.Fragment>                                
                                    {!this.state.loading && (
                                        <React.Fragment>
                                            <div className="deal-of-the-day-home-screen">
                                                <FloatCart forceUpdate={this.forceStateUpdate} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment />
                            )}
                        </div>

                        {this.state.loading_more ? (
                            <div className="">
                                <ContentLoader
                                    height={120}
                                    width={400}
                                    speed={1.2}
                                    primaryColor="#f3f3f3"
                                    secondaryColor="#ecebeb"
                                >
                                    <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                    <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                                    <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
                                </ContentLoader>
                            </div>
                        ) : null}
                    </div>
                }
			</React.Fragment>
		);
	}
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
	restaurants_featured_items: state.restaurant.restaurants_featured_items,
	cartProducts: state.cart.products,
	user: state.user.user
});

export default withRouter(
	connect(
		mapStateToProps,
		{
			getRestaurantsFeaturedItems, 
            addProduct, 
            removeProduct,            
            setFavoriteItem
		}
	)(FrequentlyBoughtList)
);
