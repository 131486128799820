import { PLACE_ORDER, PLACE_ORDER_TEMPORARY, PLACE_MEMBER_ORDER } from "./actionTypes";

const initialState = {
    checkout: [],
    checkout_temporary: [],
    checkout_member: [],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case PLACE_ORDER_TEMPORARY:
            return { ...state, checkout_temporary: action.payload };
        case PLACE_MEMBER_ORDER:
            return { ...state, checkout_member: action.payload };
        default:
            return state;
    }
}
