import { GET_DYNAMIC_FEATURED_SECTION, GET_DYNAMIC_FEATURED_URL_SECTION } from "./actionTypes";
import { 
    GET_DYNAMIC_FEATURED_SECTION_URL, 
    GET_DYNAMIC_FEATURED_SECTION_WITH_FAVORITE_URL ,
    GET_DYNAMIC_FEATURED_URL_SECTION_URL, 
    GET_DYNAMIC_FEATURED_URL_SECTION_WITH_FAVORITE_URL 
} from "../../configs";
import Axios from "axios";

export const getDynamicFeaturedSections = (lat, lng) => (dispatch, getState) => {
    var dynamic_featured_section_url =  getState().user.user.data !== undefined 
                ? GET_DYNAMIC_FEATURED_SECTION_WITH_FAVORITE_URL 
                : GET_DYNAMIC_FEATURED_SECTION_URL;
	Axios.post(dynamic_featured_section_url, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const dynamic_featured_sections = response.data;
			return dispatch({ type: GET_DYNAMIC_FEATURED_SECTION, payload: dynamic_featured_sections });
		})
		.catch(function(error) {
			console.log(error);
		});
};

export const getDynamicFeaturedUrlSections = (slug, lat, lng) => (dispatch, getState) => {
    var dynamic_featured_url_section_url =  getState().user.user.data !== undefined 
                ? GET_DYNAMIC_FEATURED_URL_SECTION_WITH_FAVORITE_URL 
                : GET_DYNAMIC_FEATURED_URL_SECTION_URL;
	Axios.post(dynamic_featured_url_section_url, {
		token: getState().user.user.data !== undefined ? getState().user.user.data.auth_token : null,
		slug: slug,
		latitude: lat,
		longitude: lng,
	})
		.then((response) => {
			const dynamic_featured_url_section = response.data;

            console.log('dynamic_featured_url_section', dynamic_featured_url_section)
			return dispatch({ type: GET_DYNAMIC_FEATURED_URL_SECTION, payload: dynamic_featured_url_section });
		})
		.catch(function(error) {
			console.log(error);
		});
};
