import { styled } from "@material-ui/core";
import React, { Component } from "react";
import Ink from "react-ink";
import Lottie from "lottie-react";
class NoAreaAlert extends Component {    
    state = {
		noServiceLottie: null,
    }
	static contextTypes = {
		router: () => null,
	};
    async componentDidMount() {
    const response = await fetch('https://lottie.host/34116eb7-b7cc-4846-8702-a7dd47038793/v7aJy7b50T.json');
       
    const data = await response.json();
    this.setState({ noServiceLottie: data });
    }
    
	render() {
		return (
			<React.Fragment>                
                <div
                    className={"col-sm-12 pt-10 text-center "}
                    style={{height:'100vh'}}
                >
                    {/* <img width="70%" src="/assets/img/various/no-area-found.png" alt="No area found" /> */}
                    <div className="d-flex justify-content-center">
                    <Lottie style={{height:300}} animationData={this.state.noServiceLottie} loop={true} />
                    </div>
                    <p className="pt-10" style={{ 
                        fontSize: '15pt',
                        fontWeight: '500'
                    }}>
                        {localStorage.getItem("noAreaMessage")}
                    </p>

                    <button
                            type="submit"
                            className="mb-5"
                            style={{ position: "relative", maxWidth: "100%",background:'none',border:'none' }}
                            onClick={() => {
                                this.context.router.history.push("/search-location");
                            }}
                        >
                        {localStorage.getItem("userSetAddress") && (
                            <React.Fragment>
                                <div style={{borderRadius:8,padding:'10px 12px',background:localStorage.getItem("cartColorBg"),position:'relative'}}>
                                <span style={{fontSize:14,fontWeight:500,color:'white'}}>Update Location</span>
                                </div>
                            </React.Fragment>
                        )}
                        <Ink duration="500" />
                    </button>
                </div>
			</React.Fragment>
		);
	}
}

export default NoAreaAlert;
