import { GET_MEMBERS_CATEGORIES, GET_MEMBERS_ITEMS } from "./actionTypes";

const initialState = {
    member_categories: [],
    member_items: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_MEMBERS_CATEGORIES:
            return { ...state, member_categories: action.payload };
        case GET_MEMBERS_ITEMS:
            return { ...state, member_items: action.payload };
        default:
            return state;
    }
}
