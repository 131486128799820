import React, { Component } from "react";

import Ink from "react-ink";
import LightSpeed from "react-reveal/LightSpeed";
import WebShare from "../WebShare";
import { setFavoriteItem } from "../../../services/items/actions";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
class BackWithSearch extends Component {
	static contextTypes = {
		router: () => null,
	};
	setFavoriteItem = () => {
		const { single_item, user } = this.props;
		if (user.success) {
			if (single_item.is_favorited) {
				this.refs.heartIcon.classList.remove("is-active");
			} else {
				this.refs.heartIcon.classList.add("is-active");
			}
			this.props.setFavoriteItem(user.data.auth_token, single_item.id);
		}
	}; 

	render() {
		const { single_item, user } = this.props;
		return (
			<React.Fragment>
				<div className="col-12 p-0 fixed" style={{ zIndex: "9" }}>
					<div className="block m-0">
						<div className={`block-content p-0 ${this.props.dark && "nav-dark"}`}>
							<div className={`input-group ${this.props.boxshadow && "search-box"}`}>
								{!this.props.disable_back_button && (
									<div className="input-group-prepend">
										{this.props.back_to_home && (
											<button
												type="button"
												className="btn search-navs-btns"
												style={{ position: "relative" }}
												onClick={() => {
													setTimeout(() => {
														this.context.router.history.push("/");
													}, 200);
												}}
											>
												<img src="/assets/img/backSvg.svg" style={{width:20}} />
												{/* <i className="si si-arrow-left" /> */}
												<Ink duration="500" />
											</button>
										)}

										{this.props.goto_orders_page && (
											<button
												type="button"
												className="btn search-navs-btns"
												style={{ position: "relative" }}
												onClick={() => {
													setTimeout(() => {
														this.context.router.history.push("/my-orders");
													}, 200);
												}}
											>
												<img src="/assets/img/backSvg.svg" style={{width:20}} />
												{/* <i className="si si-arrow-left" /> */}
												<Ink duration="500" />
											</button>
										)}
										{this.props.goto_accounts_page && (
											<button
												type="button"
												className="btn search-navs-btns"
												style={{ position: "relative" }}
												onClick={() => {
													setTimeout(() => {
														this.context.router.history.push("/my-account");
													}, 200);
												}}
											>
												<img src="/assets/img/backSvg.svg" style={{width:20}} />
												{/* <i className="si si-arrow-left" /> */}
												<Ink duration="500" />
											</button>
										)}
										{!this.props.back_to_home &&
											!this.props.goto_orders_page &&
											!this.props.goto_accounts_page && (
												<button
													type="button"
													className={`btn search-navs-btns ${this.props.dark && "nav-dark"}`}
													style={{ position: "relative" }}
													onClick={() => {
														setTimeout(() => {
															this.context.router.history.goBack();
														}, 200);
													}}
												>
													<img src="/assets/img/backSvg.svg" style={{width:20}} />
													{/* <i className="si si-arrow-left" /> */}
													<Ink duration="500" />
												</button>
											)}
									</div>
								)}
								<p
									className={`form-control search-input d-flex align-items-center ${this.props.dark &&
										"nav-dark"}`}
								>
									{this.props.logo && (
										<img
											src="/assets/img/logos/logo.png"
											alt={localStorage.getItem("storeName")}
											width="120"
										/>
									)}
									{this.props.has_title ? (
										<React.Fragment>
											{this.props.from_checkout ? (
												<span className="nav-page-title">
													{localStorage.getItem("cartToPayText")}{" "}
													<span style={{ color: localStorage.getItem("storeColor") }}>
														{localStorage.getItem("currencySymbolAlign") === "left" &&
															localStorage.getItem("currencyFormat")}
														{this.props.title}
														{localStorage.getItem("currencySymbolAlign") === "right" &&
															localStorage.getItem("currencyFormat")}
													</span>
												</span>
											) : (
												<span className="nav-page-title">{this.props.title}</span>
											)}
										</React.Fragment>
									) : null}
									{this.props.has_delivery_icon && (
										<LightSpeed left>
											<img
												src="/assets/img/various/delivery-bike.png"
												alt={this.props.title}
												className="nav-page-title"
											/>
										</LightSpeed>
									)}
								</p>
								{this.props.has_restaurant_info ? (
									<div
										className="fixed-restaurant-info hidden"
										ref={(node) => {
											this.heading = node;
										}}
									>
										<span className="font-w700 fixedRestaurantName">
											{this.props.restaurant.name}
										</span>
										<br />
										<span className="font-w400 fixedRestaurantTime">
											<i className="si si-clock" /> {this.props.restaurant.delivery_time}{" "}
											{localStorage.getItem("homePageMinsText")}
										</span>
									</div>
								) : null}
								<div className="input-group-append">
								{this.props.like_button && (
									<>
								{user.success && (
                    <span onClick={this.setFavoriteItem}>
                        <div
                            ref="heartIcon"
                            className={`heart ${single_item.is_favorited && "is-active"}`}
                        />
                    </span>
                )}
									</>
			)
		}
									{!this.props.disable_search && (
										<div className="btn search-navs-btns d-flex align-items-center"
                                            
										>
                                            
                                            <Link to="/explore" className="d-flex justify-content-center align-items-center" style={{border: '1px solid rgba(2, 6, 12, .15)',
                                            boxShadow: '0 2px 12px 0 rgba(2,6,12,.04)',backgroundColor: '#f0f0f5',borderRadius:50,height:36,width:36,position:'sticky'}}>
                                                {/* <i className="si si-magnifier" /> */}
                                                <img style={{width:20}}  src="/assets/img/searchSvg.svg"/>
                                            </Link>
                                        
											<Ink duration="500" />
										</div>
									)}
									{this.props.homeButton && (
										<button
											type="button"
											className="btn search-navs-btns nav-home-btn"
											style={{ position: "relative" }}
											onClick={() => {
												setTimeout(() => {
													this.context.router.history.push("/");
												}, 200);
											}}
										>
											<i className="si si-home" />
											<Ink duration="500" />
										</button>
									)}
									{this.props.shareButton && <WebShare link={window.location.href} />}
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	restaurant_info: state.items.restaurant_info,
	cartProducts: state.cart.products,
	single_item: state.items.single_item,
	settings: state.settings.settings,
	languages: state.languages.languages,
	language: state.languages.language,
	user: state.user.user,
});
export default withRouter(connect(
	mapStateToProps,
	{
		setFavoriteItem
	}
)(BackWithSearch)
);
