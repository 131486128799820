import React, { Component } from "react";
import { Helmet } from "react-helmet";
// import useScript from 'useScript';
import  { Carousel } from 'react-responsive-carousel';

class V4 extends Component {
    state = {
        activeClass: "",
        downloadCount: 0
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
           let activeClass = 'normal';
        //    console.log('window.scrollY', window.scrollY)
           if(window.scrollY >= 100){
               activeClass = 'on-scroll';
           }
           if(window.scrollY >= 2030){
                // this.incrementCount();
           }
           this.setState({ activeClass });
        });
    }

    incrementCount = () => {
        for( let i=this.state.downloadCount; i<= 5000; i++ ) {
            setTimeout(function() {
                this.setState({
                    downloadCount: i + 1
                });
            }, 1000);
        }
    }

	render() {
		return (
            <React.Fragment>
            <Helmet>
                <link rel="stylesheet" href="/static-assets/use.fontawesome.com/releases/v5.8.2/css/all.css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap" />
                <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" rel="stylesheet" />
                <link href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jmpress/0.4.5/basic-animations.min.css" />
                <link rel="stylesheet" href="/static-assets/webfont/webfont.css" />
                <link rel="stylesheet" href="/static-assets/css/master.css" />
                <link rel="stylesheet" href="/static-assets/css/realapp.css" />
                <link rel="stylesheet" href="/static-assets/use.fontawesome.com/releases/v5.8.2/css/all.css"></link>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <div className="desktop-screen">             
            <nav className={`navbar navbar-expand-lg ${this.state.activeClass}`}>
                <div className="container">
                    <div className="logo navbar-brand">
                        <img alt="logo" src="/static-assets/img/logo.png" className="img-responsive"></img>
                        <span className="logo-tagline d-none d-sm-block">Fruits, Vegetables and More</span>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon dripicons-menu"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav ml-auto">
                            { localStorage.getItem("desktopMenuOne") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuOneLink")}>{localStorage.getItem("desktopMenuOne") !== 'null' ? localStorage.getItem("desktopMenuOne") : ''}</a>
                            </li>
                            )}
                            { localStorage.getItem("desktopMenuTwo") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuTwoLink")}>{localStorage.getItem("desktopMenuTwo") !== 'null' ? localStorage.getItem("desktopMenuTwo") : ''}</a>
                            </li>
                            )}
                            { localStorage.getItem("desktopMenuThree") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuThreeLink")}>{localStorage.getItem("desktopMenuThree") !== 'null' ? localStorage.getItem("desktopMenuThree") : ''}</a>
                            </li>
                            )}
                            { localStorage.getItem("desktopMenuFour") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuFourLink")}>{localStorage.getItem("desktopMenuFour") !== 'null' ? localStorage.getItem("desktopMenuFour") : ''}</a>
                            </li>
                            )}
                            { localStorage.getItem("desktopMenuFive") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuFiveLink")}>{localStorage.getItem("desktopMenuFive") !== 'null' ? localStorage.getItem("desktopMenuFive") : ''}</a>
                            </li>
                            )}
                            { localStorage.getItem("desktopMenuSix") !== "null" && (
                            <li className="nav-item">
                                <a className="nav-link" href={localStorage.getItem("desktopMenuSixLink")}>{localStorage.getItem("desktopMenuSix") !== 'null' ? localStorage.getItem("desktopMenuSix") : ''}</a>
                            </li>
                            )}
                        </ul>
                        <span className="navbar-text white-text">
                        </span>
                    </div>
                </div>
            </nav>
            <section className="hero" id="top">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="hero-body">
								<span className="subtext text-left">{localStorage.getItem("desktopSlogan")}</span>
								<h1 className="hero">{localStorage.getItem("desktopHeading")}</h1>
								<p className="text-muted">{localStorage.getItem("desktopSubHeading")}</p>
								<div className="action-group">
									<a href="#download" className="btn btn-primary">{localStorage.getItem("desktopDownloadApp")}</a>
									{/* <a href="javascript:" className="btn btn-primary">Shop Now</a> */}
								</div>
							</div>
						</div>
						<div className="col-md-7 order-2 order-sm-1 text-center">
							<div className="hero-showcase">
								<div className="col-md-12" id="magic-btn-gy"><div className="phone phone-use"><div className="notch"></div>
								<div className="iframe-wrapper"><iframe title="appIframe" src={window.location} frameBorder="0" id="appIframe"></iframe></div></div></div>
								<span className="floater title">Taaza Panta</span>
							</div>
						</div>
					</div>
				</div>
			</section>
        <section className="regular user-flow" id="working">
            <div className="container">
                <h1>{localStorage.getItem("desktopHowITWorks")}</h1>
                <span className="subtext">{localStorage.getItem("desktopSimpleSteps")}</span>
                <div className="row steps">

                    <div className="col-md-4 col-12">
                        <div className="step-item">
                            <img alt="test" src="/static-assets/img/search.png" />
                            <h6>{localStorage.getItem("desktopBlockOneHeading")}</h6>
                            <p>{localStorage.getItem("desktopBlockOneSubHeading")}</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="step-item">
                            <img alt="test" src="/static-assets/img/food.png" />
                            <h6>{localStorage.getItem("desktopBlockTwoHeading")}</h6>
                            <p>{localStorage.getItem("desktopBlockTwoSubHeading")}</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="step-item">
                            <img alt="test" src="/static-assets/img/delivery.png" />
                            <h6>{localStorage.getItem("desktopBlockThreeHeading")}</h6>
                            <p>{localStorage.getItem("desktopBlockThreeSubHeading")}</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section className="regular vendor-signup" id="testimonial">
            <div className="container">
                <h1>{localStorage.getItem("desktopTestimonialTitle")}</h1>
                <span className="subtext">{localStorage.getItem("desktopTestimonialSubTitle")}</span>
                <div className="store-feature-slider">
                    <Carousel showIndicators={false} autoPlay={true} showThumbs={false} infiniteLoop={true} showArrows={false} showDots={false} centerMode={false} showStatus={false}>
                        {localStorage.getItem("desktopTestimonialHeadOne") !== "null" && (
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-4 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-8 content-holder">
                                    <h3><strong>{localStorage.getItem("desktopTestimonialHeadOne")}</strong></h3>
                                    <p>{localStorage.getItem("desktopTestimonialContentOne")}</p>
                                    <p className="mt-20 text-right testimonial-cust">- {localStorage.getItem("desktopTestimonialNameOne")}</p>
                                </div>
                            </div>
                        </div>
                        )}

                        {localStorage.getItem("desktopTestimonialHeadTwo") !== "null" && (
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-4 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-8 content-holder">
                                    <h3><strong>{localStorage.getItem("desktopTestimonialHeadTwo")}</strong></h3>
                                    <p>{localStorage.getItem("desktopTestimonialContentTwo")}</p>
                                    <p className="mt-20 text-right testimonial-cust">- {localStorage.getItem("desktopTestimonialNameTwo")}</p>
                                </div>
                            </div>
                        </div>
                        )}

                        {localStorage.getItem("desktopTestimonialHeadThree") !== "null" && (
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-4 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-8 content-holder">
                                    <h3><strong>{localStorage.getItem("desktopTestimonialHeadThree")}</strong></h3>
                                    <p>{localStorage.getItem("desktopTestimonialContentThree")}</p>
                                    <p className="mt-20 text-right testimonial-cust">- {localStorage.getItem("desktopTestimonialNameThree")}</p>
                                </div>
                            </div>
                        </div>
                        )}

                        {localStorage.getItem("desktopTestimonialHeadFour") !== "null" && (
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-4 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-8 content-holder">
                                    <h3><strong>{localStorage.getItem("desktopTestimonialHeadFour")}</strong></h3>
                                    <p>{localStorage.getItem("desktopTestimonialContentFour")}</p>
                                    <p className="mt-20 text-right testimonial-cust">- {localStorage.getItem("desktopTestimonialNameFour")}</p>
                                </div>
                            </div>
                        </div>
                        )}

                        {localStorage.getItem("desktopTestimonialHeadFive") !== "null" && (
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-4 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-8 content-holder">
                                    <h3><strong>{localStorage.getItem("desktopTestimonialHeadFive")}</strong></h3>
                                    <p>{localStorage.getItem("desktopTestimonialContentFive")}</p>
                                    <p className="mt-20 text-right testimonial-cust">- {localStorage.getItem("desktopTestimonialNameFive")}</p>
                                </div>
                            </div>
                        </div>
                        )}
                    </Carousel>
                </div>
            </div>
        </section>
        <section className="regular dark app-download" id="download">

            <div className="container">
                <h1>{localStorage.getItem("desktopDownloadSectionHeading")}</h1>
                <span className="subtext">{localStorage.getItem("desktopDownloadSectionSub")}</span>
                <div className="row">
                    <div className="col-md-6 downlink-box">
                        {/* <p>Taaza Panta is available across playstore and web</p> */}
                        <a href={localStorage.getItem("googlePlayStoreLink")}>
                        <img alt="Google Play Store" src="/static-assets/img/playstore.png" className="app-download" /> </a>
                        <span>OR</span>
                        <a href={localStorage.getItem("appStoreLink")}>
                        <img alt="App Store" src="/static-assets/img/appstore.png" className="app-download" /></a>
                    </div>
                    <div className="col-md-6">
                        <div className="row feature-group">
                            <div className="col-md-6 col-12">
                                <div className="feature-snip" data-tilt>
                                    <img alt="test" src="/static-assets/img/clock.png" />
                                    <h6>{localStorage.getItem("desktopDownloadSectionBlockOneHeader")}</h6>
                                    <p>{localStorage.getItem("desktopDownloadSectionBlockOneSub")}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="feature-snip" data-tilt>
                                    <img alt="test" src="/static-assets/img/support.png" />
                                    <h6>{localStorage.getItem("desktopDownloadSectionBlockTwoHeader")}</h6>
                                    <p>{localStorage.getItem("desktopDownloadSectionBlockTwoSub")}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="feature-snip" data-tilt>
                                    <img alt="test" src="/static-assets/img/gift.png" />
                                    <h6>{localStorage.getItem("desktopDownloadSectionBlockThreeHeader")}</h6>
                                    <p>{localStorage.getItem("desktopDownloadSectionBlockThreeSub")}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="feature-snip" data-tilt>
                                    <img alt="test" src="/static-assets/img/payment.png" />
                                    <h6>{localStorage.getItem("desktopDownloadSectionBlockFourHeader")}</h6>
                                    <p>{localStorage.getItem("desktopDownloadSectionBlockFourSub")}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="regular vendor-signup" id="sell-with-us">
            <div className="container">
                <div className="store-feature-slider">
                    <Carousel showIndicators={false} autoPlay={true} showThumbs={false} infiniteLoop={true} showArrows={false} showDots={false} centerMode={false} showStatus={false}>
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-6 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/delivery_il.svg" />
                                </div>
                                <div className="col-md-6 content-holder">
                                    <h2>{localStorage.getItem("desktopSliderSectionOneHeader")}</h2>
                                    <p>{localStorage.getItem("desktopSliderSectionOneSub")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-6 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/app_il.svg" />
                                </div>
                                <div className="col-md-6 content-holder">
                                    <h2>{localStorage.getItem("desktopSliderSectionTwoHeader")}</h2>
                                    <p>{localStorage.getItem("desktopSliderSectionTwoSub")}</p>
                                </div>
                            </div>
                        </div>

                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-6 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/visibility_il.svg" />
                                </div>
                                <div className="col-md-6 content-holder">
                                    <h2>{localStorage.getItem("desktopSliderSectionThreeHeader")}</h2>
                                    <p>{localStorage.getItem("desktopSliderSectionThreeSub")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="store-feature-element">
                            <div className="row">
                                <div className="col-md-6 img-holder">
                                    <img alt="test" className="owl-lazy" src="/static-assets/img/deal_il.svg" />
                                </div>
                                <div className="col-md-6 content-holder">
                                    <h2>{localStorage.getItem("desktopSliderSectionFourHeader")}</h2>
                                    <p>{localStorage.getItem("desktopSliderSectionFourSub")}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
        {/* <section className="regular dark stats" id="counter">
            <div className="container">
                <div className="row">

                    <div className="col-md-3">
                        <h6>DOWNLOADS</h6>
                        <h1><span className="counter-value" data-count="5000">5000</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>STORES</h6>
                        <h1><span className="counter-value" data-count="45">45</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>ITEMS</h6>
                        <h1><span className="counter-value" data-count="3200">3200</span>+</h1>
                    </div>

                    <div className="col-md-3">
                        <h6>ORDERS</h6>
                        <h1><span className="counter-value" data-count="10000">10000</span>+</h1>
                    </div>

                </div>
            </div>
        </section> */}
    <footer>
		<div className="container contact-snip" id="contact">
			{/* <h1>Reach Us</h1> */}
			<div className="row">
				<div className="col-md-4 col-6">
					<span className="icons dripicons-phone"></span>
					<span className="text">{localStorage.getItem("desktopContactSectionPhone")}</span>
				</div>

				<div className="col-md-4 col-6">
					<span className="icons dripicons-mail"></span>
					<span className="text"><a href="mailto:support@taazapanta.com" className="emaillink">{localStorage.getItem("desktopContactSectionEmail")}</a></span>
				</div>

				<div className="col-md-4 col-12">
					<span className="icons dripicons-location"></span>
					<span className="text">{localStorage.getItem("desktopContactSectionAddress")}</span>
				</div>
			</div>
		</div>
		<div className="container">
			<div className="row">

				<div className="col-md-3 text-center">
					<img alt="test" src="/static-assets/img/logo_white.png" width="250px" className="logo" />
				</div>
				<div className="col-md-3">
					<h6>{localStorage.getItem("desktopFooterSectionHeaderOne")}</h6>
					<ul className="links">
						<li><a href={localStorage.getItem("desktopFooterSectionOneLinkOne")}>{localStorage.getItem("desktopFooterSectionOneRowOne")}</a></li>
						<li><a href={localStorage.getItem("desktopFooterSectionOneLinkTwo")}>{localStorage.getItem("desktopFooterSectionOneRowTwo")}</a></li>
						<li><a href={localStorage.getItem("desktopFooterSectionOneLinkThree")}>{localStorage.getItem("desktopFooterSectionOneRowThree")}</a></li>
					</ul>
				</div>
				<div className="col-md-3">
                    <h6>{localStorage.getItem("desktopFooterSectionHeaderTwo")}</h6>
					<ul className="cities">
						<li><a href={localStorage.getItem("desktopFooterSectionTwoLinkOne")}>{localStorage.getItem("desktopFooterSectionTwoRowOne")}</a></li>
						<li><a href={localStorage.getItem("desktopFooterSectionTwoLinkTwo")}>{localStorage.getItem("desktopFooterSectionTwoRowTwo")}</a></li>
						<li><a href={localStorage.getItem("desktopFooterSectionTwoLinkThree")}>{localStorage.getItem("desktopFooterSectionTwoRowThree")}</a></li>
					</ul>
				</div>
				<div className="col-md-3">
                    <h6>{localStorage.getItem("desktopFooterSectionHeaderThree")}</h6>
					<ul className="">
                        <li>{localStorage.getItem("desktopFooterSectionThreeRowOne")}</li>
                        <li>{localStorage.getItem("desktopFooterSectionThreeRowTwo")}</li>
                        <li>{localStorage.getItem("desktopFooterSectionThreeRowThree")}</li>
                    </ul>
				</div>
				<div className="col-8 copyright">
					<p className="text-left">{localStorage.getItem("desktopFooterCopyright")}</p>
				</div>
				<div className="col-4 copyright">
					<p className="text-right">
                        {localStorage.getItem("desktopSocialFacebookLink") !== "null" && (
                            <a
                                href={localStorage.getItem("desktopSocialFacebookLink")}
                                className="btn btn-rounded btn-alt-secondary mr-10"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-facebook" style={{ fontSize:"1.0rem" }} />
                            </a>
                        )}

                        {localStorage.getItem("desktopSocialGoogleLink") !== "null" && (
                            <a
                                href={localStorage.getItem("desktopSocialGoogleLink")}
                                className="btn btn-rounded btn-alt-secondary mr-10"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-twitter" style={{ fontSize:"1.0rem" }} />
                            </a>
                        )}

                        {localStorage.getItem("desktopSocialYoutubeLink") !== "null" && (
                            <a
                                href={localStorage.getItem("desktopSocialYoutubeLink")}
                                className="btn btn-rounded btn-alt-secondary mr-10"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-youtube-play" style={{ fontSize:"1.0rem" }} />
                            </a>
                        )}

                        {localStorage.getItem("desktopSocialInstagramLink") !== "null" && (
                            <a
                                href={localStorage.getItem("desktopSocialInstagramLink")}
                                className="btn btn-rounded btn-alt-secondary mr-10"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-instagram" style={{ fontSize:"1.0rem" }} />
                            </a>
                        )}
                    </p>
				</div>
			</div>
		</div>
	</footer></div>
    <Helmet>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script type="text/javascript" src="/static-assets/demo/stretchy-navigation/js/modernizr.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"></script>
    </Helmet>
        </React.Fragment>
            );

        }
    }
    
export default V4;