import React, { Component } from "react";
import Ink from "react-ink";

import { connect } from "react-redux";
import { Redirect } from "react-router";
import { getDynamicFeaturedSections } from "../../../services/dynamicFeaturedSection/actions";
import BackWithSearch from "../Elements/BackWithSearch";
import FloatCart from "../FloatCart";
import DynamicFeaturedSectionStyle1 from "../HomeV1/DynamicFeaturedSectionStyle1";
import DynamicFeaturedSectionStyle2 from "../HomeV1/DynamicFeaturedSectionStyle2";

class DynamicFeaturedSections extends Component {
	state = {
		update: true
	};

    componentDidMount() {
        const slug = this.props.match.params.slug;
		if (slug !== null && localStorage.getItem("userSetAddress") !== null) {
            const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
            this.props.getDynamicFeaturedSections(userSetAddress.lat, userSetAddress.lng);
        }
    }

	forceStateUpdate = () => {
        if (this.state.update) {
            this.setState({ update: false });
        } else {
            this.setState({ update: true });
        }

        return this.state.update;
	};

	render() {
        
        const slug = this.props.match.params.slug;
        if (slug === null || localStorage.getItem("userSetAddress") === null) { 
            return <Redirect to="/" />;
        }

        const { dynamic_featured_sections, history } = this.props;

		return (
			<React.Fragment>
                {dynamic_featured_sections !== undefined && (
                    <React.Fragment>
                        <div className="bg-white">                    
                            <React.Fragment>                         
                                <BackWithSearch
                                    ref={(node) => {
                                        this.child = node;
                                    }}
                                    history={history}
                                    boxshadow={false}
                                    has_restaurant_info={false}
                                    disable_search={true}
                                    homeButton={false}
                                    shareButton={true}
                                />
                            </React.Fragment>
                        </div>
                        <div className="py-50">
                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {slug == 'main' && dynamic_featured_sections.main_section !== undefined && dynamic_featured_sections.main_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {slug == 'category' && dynamic_featured_sections.category_section !== undefined && dynamic_featured_sections.category_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {slug == 'deal_of_the_day' && dynamic_featured_sections.deal_of_the_day_section !== undefined && dynamic_featured_sections.deal_of_the_day_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {slug == 'frequently_bought' && dynamic_featured_sections.frequenty_bought_section !== undefined && dynamic_featured_sections.frequenty_bought_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                        </div>

                        <FloatCart forceUpdate={this.forceStateUpdate} />
                    </React.Fragment>
                )}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	dynamic_featured_sections: state.dynamic_featured_sections.dynamic_featured_sections,
});

// export default FeaturedSections;

export default connect(
	mapStateToProps,
	{
		getDynamicFeaturedSections
	}
)(DynamicFeaturedSections);
