import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT } from "./actionTypes";

export const loadCart = (products) =>  (dispatch) => {
	console.log("Loading Action",products)
	return dispatch({type: LOAD_CART,payload: products})
};

export const addProduct = (product) => (dispatch) => {
	console.log("Adding Product Action",product)
	return dispatch({type: ADD_PRODUCT,payload: product})
};

export const removeProduct = (product) => (dispatch) => {
	console.log("From action", product);

	return dispatch({ type: REMOVE_PRODUCT, payload: product });
};
