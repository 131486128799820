import React, { Component } from "react";

import ContentLoader from "react-content-loader";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";

class CarouselSlider extends Component {

	render() {
		const { slides, secondarySlider, show_indicators, auto_play, infinite_loop, slider_Type,size,type } = this.props;
		

		const totalSlides = slides.length;
		const responsive = {
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 3,
				partialVisibilityGutter: 30
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				partialVisibilityGutter: 30
			},
			mobile: {
				breakpoint: { max: 464, min: 300 },
				items: slider_Type == "afterCategory" || size == 3  ? 3.5 : 1 | slides.length === 1 && 1.25,
				partialVisibilityGutter: -30
			},
		};

		const CustomDot = ({ index, onClick, active }) => (
			<>
				{
					index === 0 && active &&
					<>
						<div className="SXOu6">
							<span className="_3__hC d-flex justify-content-center align-items-center" style={{ background: 'rgb(54, 57, 62)',width:34,height:18 }}>
								<div style={{color:'white',fontSize:11,fontWeight:800,fontFamily:'Basis Grotesque Pro'}}>{`1/${totalSlides}`}</div>
							</span>
							<div className="_3ltwP" style={{ opacity: 0 }} />
						</div>
						<div className="SXOu6 ml-3">
							<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>
							</div>
							<div className="_3ltwP" style={{ opacity: 1 }} />
						</div>
						{
							totalSlides !== 2 &&
							<div className="SXOu6 ml-4">
								<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>
								</div>
								<div className="_3ltwP" style={{ opacity: 1 }} />
							</div>
						}



					</>
				}

				{
					index === totalSlides - 1 && active &&
					<>
						{
							totalSlides !== 2 &&
							<div className="SXOu6 mr-4">
								<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>
								</div>
								<div className="_3ltwP" style={{ opacity: 1 }} />
							</div>
						}
						<div className="SXOu6 mr-3">
							<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>
							</div>
							<div className="_3ltwP" style={{ opacity: 1 }} />
						</div>
						<div className="SXOu6">
						<span className="_3__hC d-flex justify-content-center align-items-center" style={{ background: 'rgb(54, 57, 62)',width:34,height:18 }}>
								<div style={{color:'white',fontSize:11,fontWeight:800,fontFamily:'Basis Grotesque Pro'}}>{`${totalSlides}/${totalSlides}`}</div>
							</span>
							<div className="_3ltwP" style={{ opacity: 0 }} />
						</div>
					</>
				}

				{
					active && index !== 0 && index !== totalSlides - 1 &&
					<>


						<div className="SXOu6 mr-3">
							<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>

							</div>
							<div className="_3ltwP" style={{ opacity: 1 }} />
						</div>

						<div className="SXOu6">
						<span className="_3__hC d-flex justify-content-center align-items-center" style={{ background: 'rgb(54, 57, 62)',width:34,height:18 }}>
								<div style={{color:'white',fontSize:11,fontWeight:800,fontFamily:'Basis Grotesque Pro'}}>{`${index + 1}/${totalSlides}`}</div>
							</span>
							<div className="_3ltwP" style={{ opacity: 0 }} />
						</div>
						<div className="SXOu6 ml-3">
							<div className="_3__hC" style={{ background: 'rgb(54, 57, 62)' }}>

							</div>
							<div className="_3ltwP" style={{ opacity: 1 }} />
						</div>
					</>
				}
				{/* <div className="SXOu6">
                    <span className="_3__hC" style={{background: 'rgb(54, 57, 62)'}}>
                        <div className="sc-aXZVg gEvXII">{`${index + 1}/${totalSlides}`}</div>
                    </span>
                    <div className="_3ltwP" style={{opacity: 0}} />
                </div>
          
            <div className="SXOu6">
                <span className="_3__hC _1faXS" style={{background: 'rgb(54, 57, 62)', opacity: 1, transform: 'scale(0)'}}>
                    <div className="sc-aXZVg gEvXII">{`${index + 1}/${totalSlides}`}</div>
                </span>
                <div className="_3ltwP" style={{opacity: 1}} />
            </div> */}
			</>

		);
		return (
			<React.Fragment>
				<div style={{ position: 'relative' }}>
					<div
						className={
							secondarySlider
								? "slider-wrapper secondary-slider-wrapper"
								: "slider-wrapper py-10 my-0"
						}
					>
						{slides.length === 0 ? (
							<ContentLoader
								height={98}
								width={151}
								speed={1.2}
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
							>
								<rect x="20" y="4" rx="16" ry="16" width="312" height="180" />
								<rect x="347" y="3" rx="16" ry="16" width="312" height="180" />
							</ContentLoader>
						) : (
							<>

								{
									slider_Type == "afterCategory" || size == 3 ?
										<>
										
											{
												slides.length === 1 ?
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<img style={{ height: 151, width: 98, borderRadius: 16 }} src={`https://taazapanta.com/${slides[0].data.image}`} alt="Banner" />
													</div>
													:

													<Carousel
														additionalTransfrom={0}
														swipeable={true}
														arrows={false}
														autoPlay={false}
														autoPlaySpeed={3000}
														centerMode={false}
														className={"l4FVGF"}
														containerClass={""}
														customTransition={"all .5s linear"}
														dotListClass={""}
														draggable={true}
														focusOnSelect={false}
														infinite={false}
														itemClass={""}
														keyBoardControl={true}
														minimumTouchDrag={20}
														partialVisible={false}
														pauseOnHover={true}
														renderArrowsWhenDisabled={false}
														renderButtonGroupOutside={false}
														renderDotsOutside={true}
														responsive={responsive}
														rewind={false}
														rewindWithAnimation={false}
														rtl={false}
														shouldResetAutoplay={true}
														showDots={false}
														sliderClass={""}
														customDot={<CustomDot />}
													>
														{slides.map((slide) => slide && (
															<a key={slide.data.id} className="banner-carousel--banner full" href={slide.url}>
																<div className="image-overlay" style={{ height: 151, width: 98, borderRadius: 30 }}>
																	<div className="image-overlay--overlay "></div>
																	<img src={`https://taazapanta.com/${slide.data.image}`} alt="Banner" />
																</div>
															</a>
														))}
													</Carousel>


											}
										</>
										:
										<>
										
											{

												slides.length === 1 ?
													<div className="d-flex justify-content-center">
														<img style={{  borderRadius:30,width:370, height: 239 }} src={`https://taazapanta.com/${slides[0].data.image}`} alt="Banner" />
													</div>
													:
													<>
														<Carousel
															additionalTransfrom={0}
															swipeable={true}
															arrows={false}
															autoPlay={true}
															autoPlaySpeed={2500}
															centerMode={false}
															className={"l4FVGF"}
															containerClass={""}
															customTransition={"all .5s linear"}
															dotListClass={""}
															draggable={true}
															focusOnSelect={false}
															infinite={false}
															itemClass={""}
															keyBoardControl={true}
															minimumTouchDrag={20}
															partialVisible={true}
															pauseOnHover={true}
															renderArrowsWhenDisabled={false}
															renderButtonGroupOutside={false}
															renderDotsOutside={true}
															responsive={responsive}
															rewind={true}
															rewindWithAnimation={true}
															rtl={false}
															shouldResetAutoplay={true}
															showDots={true}
															sliderClass={""}
															customDot={<CustomDot />}
														>
															{slides.map((slide) => slide && (
																<a key={slide.data.id} className="banner-carousel--banner full" href={slide.url}>
																	<div className="image-overlay" style={{ width:310, height: 185, borderRadius: 30, }}>
																		<div className="image-overlay--overlay "></div>
																		<img src={`https://taazapanta.com/${slide.data.image}`} alt="Banner" />
																	</div>
																</a>
															))}
														</Carousel>
													</>
											}
										</>
								}
							</>)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default CarouselSlider;
