import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";
// import LazyLoad from "react-lazyload";

import { withRouter } from "react-router-dom";
// import Customization from "../../CategoryItemsV1/Customization";
import FloatCart from "../../FloatCart";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getRestaurantsDealItems } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import Variations from "../../CategoryItemsV1/Variations";
import Customization from "../../Items/Customization";
import { setFavoriteItem } from "../../../../services/items/actions";
import MemberVariants from "../../MembersMart/MemberVariants";
class DealOfTheDayList extends Component {
	state = {
		update: true,
		total: null,
		restaurants_deal_items: [],
		loading: false,
		loading_more: true,
		no_restaurants_deal_items: false
	};

	componentDidMount() {
		this.__getRestaurantsDealItems();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState !== this.state.data) {
			return true;
		} else {
			return false;
		}
	}

	__getRestaurantsDealItems = () => {
		if (localStorage.getItem("userSetAddress")) {
			this.setState({
				loading: true,
			});
			const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
			this.props.getRestaurantsDealItems(userSetAddress.lat, userSetAddress.lng).then((restaurants_deal_items) => {
				if (restaurants_deal_items && restaurants_deal_items.payload.length) {
					this.setState({
						total: restaurants_deal_items.payload.length,
						no_restaurants_deal_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_restaurants_deal_items: true,
					});
				}
			});
		}
	};

	forceStateUpdate = () => {
        this.forceUpdate();
        const restaurant = this.props.match.params.restaurant;

        if(restaurant !== 'all') {
            this.props.forceStateUpdate();
        }

		setTimeout(() => {
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
	};

    __getSalePrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.sale_price !== undefined) {
            let sale_price = variant.sale_price;
            return parseFloat(sale_price).toFixed(0);
        }
        return 0;
    }

    __getRegularPrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.regular_price !== undefined) {
            let sale_price = variant.sale_price;
            let regular_price = variant.regular_price;
            if(sale_price !== regular_price) {
                return parseFloat(regular_price).toFixed(0);   
            }
        }
        return false;
    }

    __getDiscountPercentage = (item) => {
        var discount = 0;
        if(item.has_variant) {
            let sale_price = this.__getSalePrice(item.variants);
            let regular_price = this.__getRegularPrice(item.variants);
            if(parseFloat(sale_price) >= parseFloat(regular_price)) {
                return false;
            }
            discount = ( ( parseFloat(regular_price) - parseFloat(sale_price) ) / parseFloat(regular_price) ) * 100;
        }
        return discount !== 0 ? discount.toFixed(0) : false;
    }

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item) => {
        if(!item.has_variant) return true;
        
        if(item.variant_type === "P") {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if(is_item_out_of_stock.length > 0) { return true; }
            
            return item.variant_stock > 0;
        }
    }

	setFavoriteItem = (single_item, event) => {
		const { user } = this.props;
		if (user.success) {
			event.target.classList.toggle("is-active");
			this.props.setFavoriteItem(user.data.auth_token, single_item.id).then(res => this.props.updateRestaurantItemsState(true));
		}
	};

	render() {
		const { addProduct, removeProduct, cartProducts, forceRestaurantItemsUpdate } = this.props;

        const isItemExistInCart = (item_id) => {
            let cc = cartProducts.find(cartItem => {
                return cartItem.id === item_id
            });
            if(cc !== undefined) {
                return true;
            }
            return false;
        }

		const { history, user } = this.props;
        const restaurant = this.props.match.params.restaurant;

        if(forceRestaurantItemsUpdate === true) {
            const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
			this.props.getRestaurantsDealItems(userSetAddress.lat, userSetAddress.lng).then((restaurants_deal_items) => {
				if (restaurants_deal_items && restaurants_deal_items.payload.length) {
					this.setState({
						total: restaurants_deal_items.payload.length,
						no_restaurants_deal_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_restaurants_deal_items: true,
					});
				}
			});
            this.props.updateRestaurantItemsState(false);
        }

		return (
			<React.Fragment>
                { restaurant !== undefined ? (
                    <div className="bg-white">                    
                            <React.Fragment>                         
                                <BackWithSearch
                                    ref={(node) => {
                                        this.child = node;
                                    }}
                                    history={history}
                                    boxshadow={false}
                                    has_restaurant_info={true}
                                    restaurant={restaurant}
                                    disable_search={true}
                                    homeButton={false}
                                    shareButton={true}
                                />
                            </React.Fragment>
                    </div>
                ) : (
                    <React.Fragment></React.Fragment>
                )}

				<div className={"" + (restaurant !== undefined ? ' pt-50 view-all-deal-of-the-day-section' : '')}>
                    <div>
                        <img style={{width:'100%'}} src="/assets/img/dealsofdayBanner.avif" />
                    </div>
					{this.state.loading ? (
						<ContentLoader
							height={378}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

							<rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

							<rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
						</ContentLoader>
					) : (
						<React.Fragment>
                            {/* <div className="deal-title-section  pt-30 px-15 pb-0" style={{background:'white'}}>                                
                                <span className="title"><strong style={{fontSize:18}}>{localStorage.getItem('dealSectionTitle')}</strong></span>
                                <span className="subtitle">{localStorage.getItem('dealSectionSubTitle')}</span>

                                { restaurant !== undefined ? (
                                    <React.Fragment></React.Fragment>
                                ) : (
                                    // <DelayLink
                                    //     to={"deal-of-the-day-items/all"}
                                    //     delay={200}
                                    //     className="block text-center mb-3 view-all"
                                    // >
                                    //     View All
                                    //     <i
                                    //         className="si si-arrow-right nav-location-icon ml-1"
                                    //     />
                                    // </DelayLink>
                                    <></>
                                )}
                            </div> */}
                            <div className="deal-content-section" style={{background:'white'}}>
                                
                                {this.props.restaurants_deal_items.length === 0 ? (
                                    <ContentLoader
                                        height={378}
                                        width={400}
                                        speed={1.2}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb"
                                    >
                                        <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                                        <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                                        <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                                    </ContentLoader>
                                ) : (
                                    <>
                                  
                                    {this.props.restaurants_deal_items.map((item, index) => (
                                        <>
                                        {
                                            item.is_free_item !== 1 &&
                                                <React.Fragment key={item.id}>
                                                <div className="single-deal-section mb-25" style={{width:160,height:270,background:'white',padding:0,marginRight:15}}>
                                                                    {!this.__itemAvailability(item) && (
                                                                        <div className="blur-content-grid">{localStorage.getItem('outOfStockText')}</div>
                                                                    )}
                                                                    <div className={` ${!this.__itemAvailability(item) ? 'blur-content-section' : ''}`}
                                                                    style={{padding:0,height:'100%',width:160}}>
                                                                        {/* <div className="item-favorites m-5">
                                                                            <span className="item-ribbon text-left"
                                                                                style={{
                                                                                    visibility: !this.__getDiscountPercentage(item) ? "hidden" : "" 
                                                                                }}
                                                                            >
                                                                                {this.__getDiscountPercentage(item) ? this.__getDiscountPercentage(item) : '&nbsp;'}% OFF
                                                                            </span>
                                                                            {user.success && (
                                                                                <span
                                                                                    onClick={(event) => this.setFavoriteItem(item, event)}
                                                                                    className={`item-favorite text-right heart ${item.is_favorited && "is-active"} heartIcon` + item.id}
                                                                                />
                                                                            )}
                                                                        </div> */}
                                                                        <DelayLink
                                                                            to={"../stores/" + item.restaurant_slug + "/" + item.id}
                                                                            delay={200}
                                                                        >
                                                                            <div style={{backgroundImage:`url(${item.image})`,backgroundSize:'contain',
                                                                                        backgroundRepeat:'no-repeat',backgroundPosition:'center',
                                                                                        position:'relative',lineHeight:'normal',width:'100%',height:128,border:'1px solid #f2f3f3',borderRadius:12}}
                                                                                className="item-image-section">
                                                                                    <div className="item-ribbon text-left"
                                                                                style={{
                                                                                    visibility: !this.__getDiscountPercentage(item) || this.__getDiscountPercentage(item) == 'NaN' ? "hidden" : "" ,
                                                                                    position:'absolute',
                                                                                    top:0,
                                                                                    left:0,
                                                                                    display:'flex',
                                                                                    justifyContent:'center',
                                                                                    alignItems:'center'
                                                                                }}
                                                                            >
                                                                                <img style={{width:30,height:32,borderTopLeftRadius:10}} src="/assets/img/offer_tag.avif" />
                                                                                <span style={{position:'absolute',top:0,left:0,fontWeight: 600,fontSize: 12,
                                                                                lineHeight:'11px',letterSpacing: '-0.4px',color:'white',padding:4,textAlign:'center'}}>
                                                                                
                                                                                {this.__getDiscountPercentage(item) ? this.__getDiscountPercentage(item) : '&nbsp;'}%
                                                                                <br/>
                                                                                OFF
                                                                                </span>
                                                                            </div>
                                                                            <div className="item-badge" style={{position:'absolute',left:0,bottom:10}}>
                                                                                    {localStorage.getItem("showVegNonVegBadge") === "true" && item.is_veg !== null && (
                                                                                        <React.Fragment>
                                                                                            {item.is_veg ? (
                                                                                                <img
                                                                                                    src="/assets/img/various/veg-icon-bg.png"
                                                                                                    alt="Veg"
                                                                                                    className="mr-1 veg-non-veg-badge"
                                                                                                />
                                                                                            ) : (
                                                                                                <img
                                                                                                    src="/assets/img/various/non-veg-icon-bg.png"
                                                                                                    alt="Non-Veg"
                                                                                                    className="mr-1 veg-non-veg-badge"
                                                                                                />
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>

                                                                                {/* <img
                                                                                    src={item.image}
                                                                                    alt={item.name}
                                                                                    className="slider-wrapper__img slider-cust-img"
                                                                                /> */}
                                                                            </div>
                                                                            <div className="deal-content-name px-2 mt-3">
                                                                                {/* {item.name} */}
                                                                                {/* {item.nutritional_facts} */}
                                                                                {console.log(item,"lllll")}
                                                                                {item.name.length > 25 ? `${item.name.substring(0, 25)}...` : item.name}
                                                                            </div>
                                                                            {/* <div className="item-details">
                                                                                <div className="font-w600 text-left">
                                                                                    <span className="item-title">
                                                                                        <strong>
                                                                                            {item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}
                                                                                        </strong>
                                                                                    </span>
                                                                                    {item.has_variant ? (                                                    
                                                                                        <span className="item-subtitle text-normal" style={{ 
                                                                                            visibility: "hidden"
                                                                                            }}>                                                                
                                                                                            {item.variants.find(function(o) { return o.is_default === 1 } ).name}
                                                                                        </span>
                                                                                    ) : (                                                
                                                                                        <span className="item-subtitle text-normal">&nbsp;</span>
                                                                                    )}
                                                                                </div>
                                                                            
                                                                                <div className="item-color-price font-w600 text-left mt-10">   
                                                                                    {item.has_variant ? (       
                                                                                        <React.Fragment>
                                                                                            <span className="sale-price">                                                                
                                                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                                    localStorage.getItem("currencyFormat")}{" "}
                                                                                                    {this.__getSalePrice(item.variants)}
                                                                                            </span>     
                                                                                            {this.__getRegularPrice(item.variants) && (                                                   
                                                                                                <span className="regular-price">       
                                                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                                        localStorage.getItem("currencyFormat")}
                                                                                                        <s>{this.__getRegularPrice(item.variants)}</s>
                                                                                                </span> 
                                                                                            )} 
                                                                                        </React.Fragment>                                             
                                                                                    ) : (                                                    
                                                                                        <span>
                                                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                                localStorage.getItem("currencyFormat")}{" "}
                                                                                                {this.__formatPrice(item.price)}
                                                                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                                                                localStorage.getItem("currencyFormat")}
                                                                                        </span>
                                                                                    )}     
                                                                                </div>
                                                                            </div> */}
                                                                            <Ink duration="500" hasTouch={false} />
                                                                        </DelayLink>
                                                                    
                                                                        <div className="item-actions  px-1" style={{position:'relative'}}>
                                                                            {item.is_active ? (
                                                                                <React.Fragment>
                                                                                    {isItemExistInCart(item.id) ? (
                                                                                        <React.Fragment>
                                                                                            {item.has_variant && item.variants.length > 0 ? (
                                                                                                // <Variations  
                                                                                                //     product={item}
                                                                                                //     addProduct={addProduct}
                                                                                                //     cartProducts={cartProducts}
                                                                                                //     forceUpdate={this.forceStateUpdate}
                                                                                                //     doForceUpdate={this.state.update}
                                                                                                //     addByDefault={true}
                                                                                                //     showDefaultVariant={true}
                                                                                                // />
                                                                                                <MemberVariants
                                                                                                product={item}
                                                                                                addMemberProduct={addProduct}
                                                                                                cartMemberProducts={cartProducts}
                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                doForceUpdate={this.state.update}
                                                                                                showDefaultVariant={true}
                                                                                                itemType="dealsItem"
                                                                                                hideAdd={false}
                                                                                            />
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <div
                                                                                                        className="btn-group btn-group-sm"
                                                                                                        role="group"
                                                                                                        aria-label="btnGroupIcons1"
                                                                                                    >
                                                                                                        {item.addon_categories.length ? (
                                                                                                            <React.Fragment>
                                                                                                                <button
                                                                                                                    disabled
                                                                                                                    type="button"
                                                                                                                    className="btn btn-add-remove"
                                                                                                                    style={{
                                                                                                                        color: localStorage.getItem("cartColor-bg"),
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="btn-dec">-</span>
                                                                                                                    <Ink duration="500" />
                                                                                                                </button>                                                                      

                                                                                                                <input type="text" readOnly value={ cartProducts.find(
                                                                                                                    (cp) =>
                                                                                                                        cp.id ===
                                                                                                                        item.id
                                                                                                                ).quantity} />
                                                                                                            </React.Fragment>
                                                                                                        ) : (
                                                                                                            <React.Fragment>
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-add-remove"
                                                                                                                    style={{
                                                                                                                        color: localStorage.getItem("cartColor-bg"),
                                                                                                                    }}
                                                                                                                    onClick={() => {
                                                                                                                        item.quantity = 1;
                                                                                                                        removeProduct(item);
                                                                                                                        this.forceStateUpdate();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="btn-dec">-</span>
                                                                                                                    <Ink duration="500" />
                                                                                                                </button>                                                                            

                                                                                                                <input type="text" readOnly value={ cartProducts.find(
                                                                                                                    (cp) =>
                                                                                                                        cp.id ===
                                                                                                                        item.id
                                                                                                                ).quantity} />
                                                                                                            </React.Fragment>
                                                                                                        )}

                                                                                                        {item.addon_categories.length ? (
                                                                                                            <Customization
                                                                                                                product={item}
                                                                                                                addProduct={addProduct}
                                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-add-remove"
                                                                                                                style={{
                                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    addProduct(item);
                                                                                                                    this.forceStateUpdate();
                                                                                                                }}
                                                                                                            >
                                                                                                                <span className="btn-inc">+</span>
                                                                                                                <Ink duration="500" />
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {item.has_variant && item.variants.length > 0 ? (
                                                                                                // <Variations                                                                  
                                                                                                //     product={item}
                                                                                                //     addProduct={addProduct}
                                                                                                //     cartProducts={cartProducts}
                                                                                                //     forceUpdate={this.forceStateUpdate}
                                                                                                //     doForceUpdate={this.state.update}
                                                                                                //     addByDefault={true}
                                                                                                //     showDefaultVariant={true}
                                                                                                // />
                                                                                                <MemberVariants
                                                                                                product={item}
                                                                                                addMemberProduct={addProduct}
                                                                                                cartMemberProducts={cartProducts}
                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                doForceUpdate={this.state.update}
                                                                                                showDefaultVariant={true}
                                                                                                itemType="dealsItem"
                                                                                                hideAdd={false}
                                                                                                />
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <div
                                                                                                        className="btn-group btn-group-sm"
                                                                                                        role="group"
                                                                                                        aria-label="btnGroupIcons1"
                                                                                                    >
                                                                                                        {item.addon_categories.length ? (
                                                                                                            <Customization
                                                                                                                product={item}
                                                                                                                addProduct={addProduct}
                                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-add-remove btn-custom-add"
                                                                                                                style={{
                                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    item.quantity = 1;
                                                                                                                    addProduct(item);
                                                                                                                    this.forceStateUpdate();
                                                                                                                }}
                                                                                                            >
                                                                                                                Add
                                                                                                                <Ink duration="500" />
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <div className="text-danger text-item-not-available">
                                                                                    {localStorage.getItem("cartItemNotAvailable")}
                                                                                </div>
                                                                            )}
                                                                            {item.addon_categories.length > 0 && (
                                                                                <React.Fragment>
                                                                                    <span
                                                                                        className="customizable-item-text d-block text-center"
                                                                                        style={{
                                                                                            color: localStorage.getItem("storeColor"),
                                                                                        }}
                                                                                    >
                                                                                        {localStorage.getItem("customizableItemText")}
                                                                                    </span>
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                </React.Fragment>
                                        }

                                        </>
                                    ))}
                                    <div className="single-deal-section mb-25" style={{width:160,height:270,padding:0,background:'white'}}>
                                    <div className="d-flex justify-content-center align-items-center" style={{padding:0,height:'100%',width:160}}>
                                        <div>
                                        <DelayLink
                                        to={"deal-of-the-day-items/all"}
                                        delay={200}
                                        className="block text-center mb-3 view-all" style={{borderRadius:8,padding:'10px 12px',background:localStorage.getItem("cartColorBg"),position:'relative'}}>
                                            <span style={{fontSize:14,fontWeight:500,color:'white'}}>View All 
                                            <i style={{fontSize:12,fontWeight:500,color:'white'}} className="si si-arrow-right nav-location-icon ml-1"/>
                                            </span>
                                    </DelayLink>
                                        </div>
                                    </div>
                                    </div>
                                    </>
                                )}
                            </div>
						</React.Fragment>
					)}

					{this.state.loading_more ? (
						<div className="">
							<ContentLoader
								height={120}
								width={400}
								speed={1.2}
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
							>
								<rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
								<rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
								<rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
							</ContentLoader>
						</div>
					) : null}
				</div>
                
				<div>
					{!this.state.loading && (
						<React.Fragment>
                            <div className="deal-of-the-day-home-screen">
                                <FloatCart forceUpdate={this.forceStateUpdate} />
                            </div>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

// export default withRouter(RestaurantList);

const mapStateToProps = (state) => ({
	restaurants_deal_items: state.restaurant.restaurants_deal_items,
	cartProducts: state.cart.products,
	user: state.user.user
});

export default withRouter(
	connect(
		mapStateToProps,
		{
			getRestaurantsDealItems, 
            addProduct, 
            removeProduct,            
            setFavoriteItem
		}
	)(DealOfTheDayList)
);
